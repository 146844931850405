import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "./styles.css";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";

function ResetPassword(props) {
  const { showResetPopup, setResetPopup } = props;

  const [passwordType, setPasswordType] = useState("password");

  const [err, setErr] = useState("");
  const [confrmPasswordType, setConfrmPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confrmPasswordType === "password") {
      setConfrmPasswordType("text");
      return;
    }
    setConfrmPasswordType("password");
  };

  const [showPopup, setShowPopup] = useState(false);
  const toggleSubmitPopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div className="modal fade bd-example-modal-lg container mt-5">
      <Modal
        show={showResetPopup}
        onHide={() => setResetPopup(false)}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 mt-4">
            <h5 className="text-center mb-3">Reset Password</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 px-4">
            <Row>
              <Col>
                <div className="small-font my-1">
                  Password <span className="red-clr">*</span>
                </div>
                <div className="d-flex justify-content-between flex-row aligin-items-center input-btn-bg w-100 rounded">
                  <input
                    type={passwordType}
                    name="password"
                    placeholder="Enter"
                    className="w-100 login-inputs small-font input-btn-bg px-2 py-2 all-none rounded all-none"
                  ></input>
                  <button
                    onClick={togglePassword}
                    className="all-none input-btn-bg"
                  >
                    {passwordType === "password" ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col>
                <div className="small-font my-1">
                  Confirm Password <span className="red-clr">*</span>
                </div>
                <div className="d-flex justify-content-between flex-row aligin-items-center input-btn-bg w-100 rounded">
                  <input
                    type={confrmPasswordType}
                    name="confirm_password"
                    placeholder="Re-enter"
                    className="w-100  small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                  ></input>
                  <button
                    onClick={toggleConfirmPassword}
                    className="all-none input-btn-bg"
                  >
                    {confrmPasswordType === "password" ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
              </Col>
            </Row>

            {err && <div className="error-message mb-1">{err}</div>}
            <div className="d-flex justify-content-center w-100 my-4">
              <div
                className="add-button rounded p-2 w-50 medium-font text-center pointer"
                onClick={toggleSubmitPopup}
              >
                Update
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <MatchSubmitPopup
        header={"Reset Password Successfully"}
        state={showPopup}
        setState={setShowPopup}
      />
    </div>
  );
}

export default ResetPassword;
