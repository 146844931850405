import React, { useRef, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import {
  FaCircleDollarToSlot,
  FaFileInvoiceDollar,
  FaSackDollar,
} from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import Table from "./../table/Table";
import DatePicker from "react-datepicker";
import GatewaysViewPopUp from "../Popups/GatewaysViewPopUp";

const GatewayTransaction = () => {
  const [detailsModal, setDetailsModal] = useState(false);
  const handleViewDetailsModal = () => {
    setDetailsModal(!detailsModal);
  };
  const transCols = [
    { header: <div>DATE & TIME</div>, field: "datetime" },
    { header: <div>NAME & ROLE</div>, field: "namerole" },
    { header: <div>PKG NAME & TRNS ID</div>, field: "pkgamtid" },
    { header: <div>D/W</div>, field: "dw" },
    { header: <div>PKG QTY</div>, field: "pkgqty" },
    { header: <div>PURCHASE AMOUNT</div>, field: "puramt" },
    { header: <div>CURRENCY</div>, field: "curr" },
    { header: <div>CURRENCY AMT</div>, field: "curramt" },
    { header: <div>AMT IN RS</div>, field: "amtinrs" },
    { header: <div>ACTION</div>, field: "action" },
  ];

  const footer = [
    { header: <div></div>, field: "datetime" },
    { header: <div></div>, field: "namerole" },
    { header: <div className="clr-green">DEPOSIT</div>, field: "pkgamtid" },
    { header: <div></div>, field: "dw" },
    { header: <div>500000</div>, field: "pkgqty" },
    { header: <div></div>, field: "puramt" },
    { header: <div className="clr-red">WITHDRAW</div>, field: "curr" },
    { header: <div></div>, field: "curramt" },
    { header: <div>5000000</div>, field: "amtinrs" },
    { header: <div></div>, field: "action" },
  ];

  const transData = [
    {
      datetime: (
        <div className="d-flex flex-column">
          <div>11-08-2024</div>
          <div>04:20:00</div>
        </div>
      ),
      namerole: (
        <div className="d-flex flex-column">
          <div>Srinivas</div>
          <div className="clr-yellow">Director</div>
        </div>
      ),
      pkgamtid: (
        <div className="d-flex flex-column">
          <div>Live Streaming</div>
          <div>12345678</div>
        </div>
      ),
      dw: <div className="clr-red">withdraw</div>,
      pkgqty: (
        <div className="d-flex flex-column">
          <div>30 dyas- 8</div>
          <div>90 days- 2</div>
        </div>
      ),
      puramt: <div>10000</div>,

      curr: <div>USD $</div>,
      curramt: <div className="">1000</div>,
      amtinrs: <div className="clr-red">10000</div>,
      action: (
        <div className="d-flex flex-column">
          <div className="clr-green green-bg px-3 py-1 pointer">Approved</div>
          <span className="my-1" onClick={handleViewDetailsModal}>
            <FiEye className="font-20 pointer" />
          </span>
        </div>
      ),
    },
    {
      datetime: (
        <div className="d-flex flex-column">
          <div>11-08-2024</div>
          <div>04:20:00</div>
        </div>
      ),
      namerole: (
        <div className="d-flex flex-column">
          <div>Srinivas</div>
          <div className="clr-yellow">Director</div>
        </div>
      ),
      pkgamtid: (
        <div className="d-flex flex-column">
          <div>Live Streaming</div>
          <div>12345678</div>
        </div>
      ),
      dw: <div className="clr-green">Deposit</div>,
      pkgqty: (
        <div className="d-flex flex-column">
          <div>30 dyas- 8</div>
          <div>90 days- 2</div>
        </div>
      ),
      puramt: <div>10000</div>,

      curr: <div>USD $</div>,
      curramt: <div className="">1000</div>,
      amtinrs: <div className="clr-green">10000</div>,
      action: (
        <div className="d-flex flex-column">
          <div className="clr-green green-bg px-3 py-1 pointer">Approved</div>
          <span className="my-1" onClick={handleViewDetailsModal}>
            <FiEye className="font-20 pointer" />
          </span>
        </div>
      ),
    },
    {
      datetime: (
        <div className="d-flex flex-column">
          <div>11-08-2024</div>
          <div>04:20:00</div>
        </div>
      ),
      namerole: (
        <div className="d-flex flex-column">
          <div>Srinivas</div>
          <div className="clr-yellow">Director</div>
        </div>
      ),
      pkgamtid: (
        <div className="d-flex flex-column">
          <div>Live Streaming</div>
          <div>12345678</div>
        </div>
      ),
      dw: <div className="clr-red">withdraw</div>,
      pkgqty: (
        <div className="d-flex flex-column">
          <div>30 dyas- 8</div>
          <div>90 days- 2</div>
        </div>
      ),
      puramt: <div>10000</div>,

      curr: <div>USD $</div>,
      curramt: <div className="">1000</div>,
      amtinrs: <div className="clr-red">10000</div>,
      action: (
        <div className="d-flex flex-column">
          <div className="clr-green green-bg px-3 py-1 pointer">Approved</div>
          <span className="my-1" onClick={handleViewDetailsModal}>
            <FiEye className="font-20 pointer" />
          </span>
        </div>
      ),
    },
    {
      datetime: (
        <div className="d-flex flex-column">
          <div>11-08-2024</div>
          <div>04:20:00</div>
        </div>
      ),
      namerole: (
        <div className="d-flex flex-column">
          <div>Srinivas</div>
          <div className="clr-yellow">Director</div>
        </div>
      ),
      pkgamtid: (
        <div className="d-flex flex-column">
          <div>Live Streaming</div>
          <div>12345678</div>
        </div>
      ),
      dw: <div className="clr-green">Deposit</div>,
      pkgqty: (
        <div className="d-flex flex-column">
          <div>30 dyas- 8</div>
          <div>90 days- 2</div>
        </div>
      ),
      puramt: <div>10000</div>,

      curr: <div>USD $</div>,
      curramt: <div className="">1000</div>,
      amtinrs: <div className="clr-green">10000</div>,
      action: (
        <div className="d-flex flex-column">
          <div className="clr-green green-bg px-3 py-1 pointer">Approved</div>
          <span className="my-1" onClick={handleViewDetailsModal}>
            <FiEye className="font-20 pointer" />
          </span>
        </div>
      ),
    },
  ];

  const dateRef = useRef();
  const toggleDate = () => {
    dateRef.current.setFocus();
  };

  const dateToRef = useRef();
  const toggleToDate = () => {
    dateToRef.current.setFocus();
  };
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <div className="p-3">
      <div className="row medium-font padding-unset">
        <div className="col-sm-6 col-md-4 col-lg-4 py-2 pe-2 ps-1">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>Total Deposit</span>
              <div>
                <span className="medium-font clr-green">2100000</span>
              </div>
            </div>
            <div className="fa-dollar-icon fs-2">
              <FaSackDollar />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 p-2">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>Total Withdraw</span>
              <h6 className="medium-font clr-red">807000</h6>
            </div>
            <div className="fa-c-dollar-toslot-icon fs-2">
              <FaCircleDollarToSlot />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-4 p-2">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>Net Balance</span>
              <h6 className="medium-font clr-green">2000000</h6>
            </div>
            <div className="fa-fileinvo-doll-icon fs-2">
              <FaFileInvoiceDollar />
            </div>
          </div>
        </div>
      </div>
      <div className="row medium-font padding-unset">
        <div className="col-sm-6 col-md-4 col-lg-3 py-2 pe-2 ps-1">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>Total Tickets</span>
              <div>
                <span className="medium-font">10000</span>
              </div>
            </div>
            <div className="fa-dollar-icon fs-2">
              <FaSackDollar />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 p-2">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>New Ticekts</span>
              <h6 className="medium-font">900</h6>
            </div>
            <div className="fa-c-dollar-toslot-icon fs-2">
              <FaCircleDollarToSlot />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 p-2">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>Approved Tickets</span>
              <h6 className="medium-font clr-green">90</h6>
            </div>
            <div className="fa-fileinvo-doll-icon fs-2">
              <FaFileInvoiceDollar />
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 py-2 ps-2 pe-1">
          <div className="d-flex justify-content-between home-border-radius sidebar-bg th-color p-3 align-items-center">
            <div>
              <span>Rejected Tickets</span>
              <h6 className="medium-font">10</h6>
            </div>

            <div className="bs-percircl-icon fs-2">
              <BsPersonCircle />
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-bg p-3 br-5 mt-2">
        <div className="d-flex align-items-center">
          <div className="cursor-pointer">
            <div className="th-color small-font mb-1">From</div>
            <div
              className="blue-shade-bg d-flex flex-between py-2 px-1 th-color small-font th-color"
              onClick={toggleDate}
            >
              {/* <input className="p-1 th-color all-none2 pointer" type="date"></input> */}
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                placeholderText="From"
                className="all-none blue-shade-bg"
                ref={dateRef}
              />
              <MdDateRange
                className="bluecolor-text large-font"
                onClick={toggleDate}
              />
            </div>
          </div>
          <div className="mx-3 cursor-pointer">
            <div className="th-color small-font mb-1">To</div>
            <div
              className="blue-shade-bg py-2 px-1 d-flex flex-between th-color small-font th-color"
              onClick={toggleToDate}
            >
              {/* <input
                className="p-1 th-color all-none2 pointer"
                type="date"
              ></input> */}
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="dd-MM-yyyy"
                placeholderText="To"
                className="all-none blue-shade-bg"
                ref={dateToRef}
              />
              <MdDateRange
                className="bluecolor-text large-font"
                onClick={toggleToDate}
              />
            </div>
          </div>
          <div className="pt-4 cursor-pointer">
            <div className="blue-bg d-flex th-color small-font th-color px-4 py-2">
              Submit
            </div>
          </div>
        </div>
        <div className="th-color py-2">All Transactions</div>
        <div>
          <Table columns={transCols} data={transData} footer={footer} />
        </div>
      </div>
      <GatewaysViewPopUp show={detailsModal} setShow={setDetailsModal} />
    </div>
  );
};

export default GatewayTransaction;
