import React from "react";
import { TbWorldCheck } from "react-icons/tb";
import { IoCopyOutline } from "react-icons/io5";
import { Images } from "../../images";
import DocTable from "./../table/DocTable";

const NewWebsitesLimit = () => {
  const CallPkgsCols = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">PACKGES</div>
        </div>
      ),
      field: "pkgs",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">CALL PURCHASE</div>
        </div>
      ),
      field: "callpurchase",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">CALL SALE</div>
        </div>
      ),
      field: "callsale",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">CALL BALANCE</div>
        </div>
      ),
      field: "callbal",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">T H PURCHASE</div>
        </div>
      ),
      field: "thpurchase",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">T H SALE</div>
        </div>
      ),
      field: "thsale",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">T H BALANCE</div>
        </div>
      ),
      field: "thbal",
    },
  ];

  const CallPkgsData = [
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Gold} alt="img" />
          <span className="mx-2 pkg-width text-center">Standard</span>
        </div>
      ),
      callpurchase: "1000",
      callsale: <span className="clr-yellow">1600</span>,
      callbal: <span className="clr-green">400</span>,
      thpurchase: "500",
      thsale: "300",
      thbal: "200",
    },
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Silver} alt="img" />
          <span className="mx-2 pkg-width text-center"> Silver</span>
        </div>
      ),
      callpurchase: "2000",
      callsale: <span className="clr-yellow">1600</span>,
      callbal: <span className="clr-green">400</span>,
      thpurchase: "1000",
      thsale: "800",
      thbal: "200",
    },
  
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Gold} alt="img" />
          <span className="mx-2 pkg-width text-center"> Gold</span>
        </div>
      ),
      callpurchase: "2000",
      callsale: <span className="clr-yellow">1600</span>,
      callbal: <span className="clr-green">400</span>,
      thpurchase: "1000",
      thsale: "800",
      thbal: "200",
    },
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Diamond} alt="img" />
          <span className="mx-2 pkg-width text-center "> Diamond</span>
        </div>
      ),
      callpurchase: "2000",
      callsale: <span className="clr-yellow">1600</span>,
      callbal: <span className="clr-green">400</span>,
      thpurchase: "1000",
      thsale: "800",
      thbal: "200",
    },
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Vip} alt="img" />
          <span className="mx-2 pkg-width text-center">Vip</span>
        </div>
      ),
      callpurchase: "2000",
      callsale: <span className="clr-yellow">1600</span>,
      callbal: <span className="clr-green">400</span>,
      thpurchase: "1000",
      thsale: "800",
      thbal: "200",
    },
  ];

  const LiveStreamCols = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">PACKGES</div>
        </div>
      ),
      field: "pkgs",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LIVE PURCHASE</div>
        </div>
      ),
      field: "livepurchase",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LIVE SALE</div>
        </div>
      ),
      field: "livesale",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LIVE BALANCE</div>
        </div>
      ),
      field: "livebal",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LINE PURCHASE</div>
        </div>
      ),
      field: "linepurchase",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LINE SALE</div>
        </div>
      ),
      field: "linesale",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LINE BALANCE</div>
        </div>
      ),
      field: "linebal",
    },
  ];

  const LiveStreamData = [
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Gold} alt="img" />
          <span className="mx-2">30 days</span>
        </div>
      ),
      livepurchase: "1000",
      livesale: <span className="clr-yellow">600</span>,
      livebal: <span className="clr-green">400</span>,
      linepurchase: "500",
      linesale: "300",
      linebal: "200",
    },
    {
      pkgs: (
        <div className="d-flex w-80 align-items-center">
          <img src={Images.Silver} alt="img" />
          <span className="mx-2">90 days</span>
        </div>
      ),
      livepurchase: "2000",
      livesale: <span className="clr-yellow">600</span>,
      livebal: <span className="clr-green">400</span>,
      linepurchase: "1000",
      linesale: "800",
      linebal: "200",
    },
    {
      pkgs: (
        <div className="d-flex align-items-center w-80">
          <img src={Images.Silver} alt="img" />
          <span className="mx-2">180 days</span>
        </div>
      ),
      livepurchase: "2000",
      livesale: <span className="clr-yellow">600</span>,
      livebal: <span className="clr-green">400</span>,
      linepurchase: "1000",
      linesale: "800",
      linebal: "200",
    },
  ];

  return (
    <div className="sidebar-bg rounded w-100">
      <div className="d-flex flex-between align-items-center p-3">
        <div className="d-flex flex-between align-items-center">
          <TbWorldCheck className="th-color font-20" />
          <span className="th-color mx-2 small-font">We2call</span>
          <span className="th-color">|</span>
          <span className="role-color px-1 mx-2 small-font">We2call.com</span>
          <IoCopyOutline className="th-color font-18" />
        </div>
        <div className=" d-flex align-items-center justify-content-center th-color">
          <div className="p-1 medium-font mx-1">Active</div>
          <div className="form-check form-switch d-flex align-items-center justify-content-evenly">
            <input
              className="form-check-input button-input-clr"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
            />
          </div>
          <div className="p-1 medium-font mx-1">Inactive</div>
        </div>
      </div>
      <hr className="hr-line" />
      <div className="p-3 d-flex flex-column">
        <div className="th-color w-fit-content rounded-pill empty-bg-br small-font p-1 px-2">
          Revenue Sharing
        </div>

        <div className="th-color small-font mt-2">Packages Discount</div>
        <div className="d-flex small-font mt-2">
          <div className="pkg-box clr-white px-4 py-2">40%</div>
          <div className="blue-bg clr-white px-3 py-2 mx-2">Updated</div>
        </div>
      </div>
      <hr className="hr-line" />
      <div className="p-3">
        <div className="blue-fade-bg clr-green small-font px-2 py-1">
          Calling Pkgs and Topup Pkgs
        </div>

        <div className="border-table mt-3">
          <DocTable
            columns={CallPkgsCols}
            data={CallPkgsData}
            customClass="header-bg"
          />
        </div>
      </div>

      <div className="p-3">
        <div className="blue-fade-bg clr-green small-font px-2 py-1">
          Live Streaming and Line Pkgs
        </div>
        <div className="border-table mt-3">
          <DocTable
            columns={LiveStreamCols}
            data={LiveStreamData}
            customClass="header-bg"
          />
        </div>
      </div>
    </div>
  );
};

export default NewWebsitesLimit;
