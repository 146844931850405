import React, { useRef, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import "./styles.css";
import { ADD_WEBSITE } from "../../config/endpoints";
import { call } from "../../config/axios";
import DatePicker from "react-datepicker"


import { IoIosCloudUpload } from "react-icons/io";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
import { FaRegCalendarAlt } from "react-icons/fa";
function PackagePopup(props) {
  const { showAddWebPopup, setShowAddWebPopup, setStatus, openPackageData } =
    props;
  const [acceptClick, setAcceptClick] = useState(false);
  const [lineLiveCreation,setLineLiveCreation]=useState(false)
  const [price, setPrice] = useState();
  const [formData, setFormData] = useState({
    register_id: "company",
    web_url: "",
  });

  const handleSuccessPopup=()=>{
    setLineLiveCreation(true)
  }
  const handleOnchange = (value) => {
    setPrice(value);
  };

  const handleAddWebPopupClose = () => {
    setShowAddWebPopup(false);
  };
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCreateWebsite = async () => {
    try {
      const res = await call(ADD_WEBSITE, formData);
      if (res.data.status === 200) {
        setAcceptClick(true);
        setTimeout(() => {
          setAcceptClick(false);
          setShowAddWebPopup(false);
        }, 2000);
      }
      if (res.data.error) {
        console.error("API Error:", res.data.message);
      } else {
        setAcceptClick(true);
        handleAddWebPopupClose();
        setShowAddWebPopup(false);
        setStatus((prev) => !prev);
        setFormData({
          register_id: "company",
          web_url: "",
        });
      }
    } catch (err) {
      console.error("API Error:", err);
    }
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const dateRef=useRef();
  const toggleDate = () => {
    dateRef.current.setFocus();
  };
  const dateToRef=useRef();
  const toggleToDate = () => {
    dateToRef.current.setFocus();
  };

  return (
    <div className="modal fade bd-example-modal-lg container w-75">
      <Modal
        show={showAddWebPopup}
        onHide={handleAddWebPopupClose}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 text-center mt-4">
            <h6>
              {openPackageData === "Add Logo"
                ? "Add Logo"
                : openPackageData === "Add Offer text"
                ? "Add Offer Text"
                : "Create Package"}
            </h6>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 px-4">
            <div>
              <div className="small-font ms-1">
                Select Website <span className="red-clr">*</span>
              </div>
              <select className="w-100  small-font login-inputs input-btn-bg  my-2 p-2 all-none rounded all-none">
                <option value="" className="small-font">
                  Select
                </option>
                <option value="director" className="small-font">
                  we2call.com
                </option>
                <option value="superadmin" className="small-font">
                  Txchange
                </option>
              </select>
            </div>
            <div>
              <div className="small-font ms-1">
                Country <span className="red-clr">*</span>
              </div>
              <select className="w-100  small-font login-inputs input-btn-bg my-2 p-2 all-none rounded all-none">
                <option value="" className="small-font">
                  Select
                </option>
                <option value="director" className="small-font">
                  India{" "}
                </option>
                <option value="superadmin" className="small-font">
                  Dubai
                </option>
              </select>
            </div>
            <div className="w-100 d-flex">
              <Col className="w-50 ">
                <div>
                  <div className="medium-font mb-2 clr-grey">
                    Start Date <span className="red-clr">*</span>
                  </div>
                  <div className="w-100 small-font input-btn-bg px-2 py-1 all-none rounded all-none d-flex flex-row align-items-center"
                  onClick={toggleDate}>
                    {/* <input
                      className="login-input all-none w-100"
                      type="date"
                      name="end_date"
                    ></input> */}

                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Date"
                      className="input-btn-bg all-none "
                      ref={dateRef}
                    />
                    <FaRegCalendarAlt className="upload-icon p-1 font-size-30" onClick={toggleDate}/>
                  </div>
                </div>
              </Col>
              <Col className="w-50 ms-2">
                <div>
                  <div className="medium-font mb-2 clr-grey">
                    End Date <span className="red-clr">*</span>
                  </div>
                  <div className="w-100 small-font input-btn-bg px-2 py-1 all-none rounded all-none d-flex flex-row align-items-center"
                  onClick={toggleToDate}>
                    {/* <input
                      className="login-input all-none w-100"
                      type="date"
                      name="end_date"
                    ></input> */}

                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="Date"
                      className="input-btn-bg all-none "
                      ref={dateToRef}
                    />
                    <FaRegCalendarAlt className="upload-icon p-1 font-size-30" onClick={toggleToDate}/>
                  </div>
                </div>
              </Col>
            </div>
            {openPackageData === "Add Logo" ? (
              <>
                <div className="pointer">
                  <div className="small-font ms-1 my-2 pointer">
                    Upload Logo <span className="red-clr">*</span>
                  </div>
                  <label
                    className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2 pointer"
                    htmlFor="upload-logo"
                  >
                    Select
                    <IoIosCloudUpload />
                  </label>

                  <div>
                    <input
                      type="file"
                      id="upload-logo"
                      className=" display-none"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-50 medium-font"
                    onClick={() =>{ 
                      handleSuccessPopup()
                      handleCreateWebsite()}}
                  >
                    SUBMIT
                  </button>
                </div>
              </>
            ) : openPackageData === "Add Offer text" ? (
              <>
                <div>
                  <div className="small-font ms-1 my-2">
                    Enter Text <span className="red-clr">*</span>
                  </div>

                  <div>
                    <input
                      type="text"
                      id="upload-logo"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-50 medium-font"
                    onClick={() =>{ 
                      handleSuccessPopup()
                      handleCreateWebsite()}}
                  >
                    SUBMIT
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="w-100 d-flex">
                  <div className="w-50 ">
                    <div className="small-font ms-1 my-2">
                      Package Hour <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                      />
                    </div>
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Price <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        value={price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleOnchange(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50 ">
                    <div className="small-font ms-1 my-2">
                      Members <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        value={price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleOnchange(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Call join users <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        value={price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleOnchange(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex">
                  <div className="w-50 ">
                    <div className="small-font ms-1 my-2">
                      Personal meetings <span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        value={price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleOnchange(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-50 px-2">
                    <div className="small-font ms-1 my-2">
                      Meetings<span className="red-clr">*</span>
                    </div>

                    <div>
                      <input
                        type="text"
                        id="upload-logo"
                        className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                        placeholder="Enter"
                        value={price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleOnchange(value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="small-font ms-1 my-2">
                    Discount <span className="red-clr">*</span>
                  </div>

                  <div>
                    <input
                      type="text"
                      id="upload-logo"
                      className="w-100 input-btn-bg px-2 small-font login-inputs py-2 d-flex flex-between all-none rounded mt-2"
                      placeholder="Enter"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100 mt-3 mb-4">
                  <button
                    className="add-button rounded px-2 py-2 w-50 medium-font"
                    onClick={() =>{ 
                      handleSuccessPopup()
                      handleCreateWebsite()}}
                  >
                    CREATE 
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <MatchSubmitPopup
        header={"Website Added Successfully"}
        state={acceptClick}
        setState={setAcceptClick}
        setStatus={setStatus}
        lineLiveCreation={lineLiveCreation}
        setLineLiveCreation={setLineLiveCreation}
        createPackage={"Creation Successfully"}
        
      />
    </div>
  );
}

export default PackagePopup;
