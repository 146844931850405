import React from "react";
import Table from "../table/Table";
import { Images } from "../../images";
import { call } from "../../config/axios";
import { useState, useEffect } from "react";
import { GET_ALL_USERS, USERS_ACTIVE_INACTIVE } from "../../config/endpoints";

function Meetingshedule() {
  const [allUsers, setAllUsers] = useState([]);
  const [active, setActive] = useState();

  const getAllUsers = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_USERS, payload)
      .then((res) => {
        setAllUsers(res?.data?.data?.Items);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllUsers();
  }, [active]);

  const handleBlockUnBlock = async (item, active) => {
    const payload = {
      register_id: item,
      active: !active,
    };
    await call(USERS_ACTIVE_INACTIVE, payload)
      .then((res) => {
        setActive(!active);
      })
      .catch((err) => console.log(err));
  };

  const cols = [
    {
      header: (
        <div className="font-bold medium-font text-start ms-4 ">NAME & ROLE</div>
      ),
      field: "name",
    },
    {
      header: <div className="font-bold medium-font">METTINGS</div>,
      field: "meetings",
    },
    {
      header: "",
      field: "status",
      clr: true,
    },
  ];

  const userDetails = [
    {
      name: (
        <div className="d-flex flex-between w-100">
          <div className="w-100 d-flex flex-between">
            <img src={Images.ProfileOne} alt="ProfileImage" className="mx-2" />
            <div className="d-flex flex-column">
              <div className="medium-font font-bold w-100 ">
                Goppi Krishna
              </div>
              <div className="small-font w-100 ">
                T exchange Director
              </div>
            </div>
          </div>
        </div>
      ),
      meetings: 24,
      status: <div className="font-green custom-active-button px-2">Live</div>,
      icon: "icon",
    },
    {
      name: (
        <div className="d-flex">
          <img src={Images.ProfileOne} alt="ProfileImage" className="mx-2" />
          <div>
            <div className="medium-font font-bold">Goppi Krishna</div>
            <div className="small-font">T exchange Director</div>
          </div>
        </div>
      ),
      meetings: 24,
      status: <div className="font-green custom-active-button px-2">Live</div>,
      icon: "icon",
    },
    {
      name: (
        <div className="d-flex">
          <img src={Images.ProfileOne} alt="ProfileImage" className="mx-2" />
          <div>
            <div className="medium-font font-bold">Goppi Krishna</div>
            <div className="small-font">T exchange Director</div>
          </div>
        </div>
      ),
      meetings: 24,
      status: <div className="font-green custom-active-button px-2">Live</div>,
      icon: "icon",
    },
    {
      name: (
        <div className="d-flex">
          <img src={Images.ProfileOne} alt="ProfileImage" className="mx-2" />
          <div>
            <div className="medium-font font-bold">Goppi Krishna</div>
            <div className="small-font">T exchange Director</div>
          </div>
        </div>
      ),
      meetings: 24,
      status: <div className="font-green custom-active-button px-2">Live</div>,
      icon: "icon",
    },
    {
      name: (
        <div className="d-flex">
          <img src={Images.ProfileOne} alt="ProfileImage" className="mx-2" />
          <div>
            <div className="medium-font font-bold">Goppi Krishna</div>
            <div className="small-font">T exchange Director</div>
          </div>
        </div>
      ),
      meetings: 24,
      status: <div className="font-green custom-active-button px-2">Live</div>,
      icon: "icon",
    },
  ];

  const modifiedMeetingsheduleDetails = allUsers?.map((item) => ({
    ...item,
    icon: (
      <div className="d-flex flex-between w-100">
        <div className="d-flex flex-between mx-2">
          <img src={Images.ProfileOne} alt="ProfileImage" className="mx-2" />
          <div className="pkg-width">
            <span>
              {item?.user_name} <br />
            </span>
            <span className="role-color mx-2">
              {item?.websites?.map((website) => (
                <div key={website.website_name}>
                  {website.website_name} <br />
                </div>
              ))}
              -{item?.account_role}
            </span>
          </div>
        </div>
      </div>
    ),
    meetings: <div>{item?.meeting_count}</div>,
    status:
      item?.active === true ? (
        <div
          className="font-green custom-active-button px-2"
          onClick={() => {
            handleBlockUnBlock(item?.register_id, item?.active);
          }}
        >
          Live
        </div>
      ) : (
        <div
          className="pending-btn br-10 px-2"
          onClick={() => {
            handleBlockUnBlock(item?.register_id, item?.active);
          }}
        >
          Schedule
        </div>
      ),
  }));
  return (
    <div className="w-100 sidebar-bg rounded" style={{ height: "100%" }}>
      <div className="medium-font font-weight-bold px-2 p-1 m-2 th-color">
        <h6 className="py-2 ms-4">Meeting Shedules/Live</h6>
      </div>
      <div className="sidebar-bg rounded cursor-pointer mt-2">
        <Table columns={cols} data={userDetails} setdata={"start-text"} />
      </div>
    </div>
  );
}

export default Meetingshedule;
