import React from "react";
import Table from "../table/Table";

function PackagesReport() {
  const MYSTATEMENT_DETAILS = [
    {
      name: "Srinivas",
      role: "owner",
      loadingchips: <div className="fa-fileinvo-doll-icon">500000</div>,
      totalamount: "50000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
      saleschips: <div className="fa-fileinvo-doll-icon">400000</div>,
      balancechips: <div className="fa-fileinvo-doll-icon">100000</div>,
      downlinebal: <div className="bs-percircl-icon">200000</div>,
    },
  ];

  const cols = [
    {
      header: "USERNAME ROLE",
      field: "nameAndRole",
    },
    {
      header: "PKG SALE",
      field: "loadingchips",
    },

    {
      header: "RECEIVED AMOUNT",
      field: "totalamount",
    },

    {
      header: "BALANCE AMOUNT",
      field: "paidamount",
    },
    {
      header: "DOWNLINE PKG STOCK VALUE",
      field: "balanceamount",
    },
    {
      header: "TOTAL SALE HOURS",
      field: "saleschips",
    },
    {
      header: "RECEIVED AMOUNT",
      field: "balancechips",
    },
    {
      header: "H BALANCE AMOUNT",
      field: "downlinebal",
    },
    {
      header: "DOWNLINE HOUR VALUE",
      field: "downlinebal",
    },
  ];

  const modifiedMystatementDetails = MYSTATEMENT_DETAILS.map((item) => ({
    ...item,
    nameAndRole: (
      <div>
        {item?.name} <br /> <span className="role-color">{item?.role}</span>
      </div>
    ),
  }));
  return (
    <div>
      {" "}
      <div>
        <Table columns={cols} data={modifiedMystatementDetails} />
      </div>
      <div>
        <div className="th-color  fw-600 w-100 small-font p-3 d-flex justify-content-between">
          <div className="w-25">
            <div className="row py-2">
              <div className="col">Total Sale Pkg Hours</div>
              <div className="col">500000</div>
            </div>
            <div className="row py-2">
              <div className="col">Total Received Amount</div>
              <div className="col">400000</div>
            </div>
            <div className="row py-2">
              <div className="col">Balance Amount</div>
              <div className="col">100000</div>
            </div>
            <div className="row py-2">
              <div className="col">Total P/L</div>
              <div className="col fa-fileinvo-doll-icon">400000</div>
            </div>
          </div>
          <div className="w-25 d-flex align-items-end justify-content-end">
            <div className="row">
              <div className="col">
                
                <div className="d-flex justify-content-between fw-600">
                  <div className="w-50">
                    Total Downline PKG + Hour
                  </div>
                  <div className="red-text">400000</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackagesReport;
