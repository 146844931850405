import * as actionTypes from "../actions/actionConstants";

const initialState = {
  notification_data: '',
  package_input_data: '',
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PACKAGE_INPUT_DATA:
      return {
        ...state,
        stored_package_data: action.payload,
      };
    case actionTypes.SET_NOTIFICATION_DATA:
      return{
        ...state,
        notification_data: action.payload,
      }
    default:
      return state;
  }
};

export default commonReducer;
