import "bootstrap-icons/font/bootstrap-icons.css";
import "font-awesome/css/font-awesome.min.css";
import "./style.css";
import { HiOutlineUserAdd } from "react-icons/hi";
import { LiaAdSolid } from "react-icons/lia";
import { LuCopyPlus } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ImTicket } from "react-icons/im";
import { BsBank2 } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { LuFileBarChart } from "react-icons/lu";
import { LuHeartHandshake } from "react-icons/lu";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { TbPackages } from "react-icons/tb";
import { RiUserShared2Line } from "react-icons/ri";
import { FaMoneyCheck, FaRegFileAlt } from "react-icons/fa";
import { GoXCircle } from "react-icons/go";
import { AiOutlineGlobal, AiOutlineQuestionCircle } from "react-icons/ai";
import { LuFilePlus2 } from "react-icons/lu";
import { BsBroadcast } from "react-icons/bs";
import { BiSolidOffer } from "react-icons/bi";
import { PiAirplaneTiltBold } from "react-icons/pi";
import { TiMessages } from "react-icons/ti";
import { Images } from "../../images";
import { IoClose } from "react-icons/io5";
import { MdOutlineEdit } from "react-icons/md";
import EditPopup from "../Popups/EditPopup";
import { useState } from "react";

function Sidebar({ register_id }) {
  const location = useLocation();
  const navigate = useNavigate();

  const sidebar = [
    { title: "Dashboard", path: "/", icon: "bi-grid" },
    { title: "Add Websites", path: "/addwebsites", icon: " bi-plus-circle" },
    {
      title: "Add Director/SA",
      path: "/adddirectors",
      reactIcon: <HiOutlineUserAdd />,
    },
    { title: "Add Team", path: "/addtt", reactIcon: <LiaAdSolid /> },
    {
      title: "Live Stream/Line",
      path: "/matchCreation",
      reactIcon: <LuCopyPlus />,
    },
    { title: "Add Banking", path: "/toursbanking", reactIcon: <BsBank2 /> },
    { title: "Tickets", path: "/tickets", reactIcon: <ImTicket /> },
    {
      title: "Reports",
      path: "/reportsts",
      reactIcon: <LuFileBarChart />,
      dataBsTarget: "#reports",
      ariaExpanded: "false",
      ariaControls: "reports",
      childMenu: [
        {
          title: "My Statement",
          path: "/mystatement",
          reactIcon: <FaRegFileAlt />,
        },
        {
          title: "Statement",
          path: "/statement",
          reactIcon: <FaRegFileAlt />,
        },
        {
          title: "Settlement",
          path: "/settlement",
          reactIcon: <LuHeartHandshake />,
        },
        {
          title: "Settlement-Statement",
          path: "/settlementstatement",
          reactIcon: <HiOutlineClipboardDocumentList />,
        },
      ],
    },
    {
      title: "Call-Live-Line Pkg",
      path: "/live-line-packages",
      reactIcon: <TbPackages />,
    },
    {
      title: "Reference Data",
      path: "/reference",
      reactIcon: <RiUserShared2Line />,
      dataBsTarget: "#reference",
      ariaExpanded: "false",
      ariaControls: "reference",
      childMenu: [
        {
          title: "Country/Currency",
          path: "/countrycurrency",
          reactIcon: <AiOutlineGlobal />,
        },
        {
          title: "Payment Gateway",
          path: "/paymentgateway",
          reactIcon: <FaMoneyCheck />,
        },
        {
          title: "Gateway Transaction",
          path: "/gatewaytransaction",
          reactIcon: <FaMoneyCheck />,
        },
        {
          title: "Rejection Reason",
          path: "/rejectionreason",
          reactIcon: <GoXCircle />,
        },
        {
          title: "Security Questions",
          path: "/securityquestions",
          reactIcon: <AiOutlineQuestionCircle />,
        },
        {
          title: "Policy Documents",
          path: "/policydocuments",
          reactIcon: <LuFilePlus2 />,
        },
      ],
    },
    {
      title: "Offline Broadcasting Notifications",
      path: "/broadcasting",
      reactIcon: <BsBroadcast />,
    },
    { title: "Support", path: "/support-chat", reactIcon: <TiMessages /> },
  ];

  const AccoutentRoutes = [
    { title: "Dashboard", path: "/", icon: "bi-grid" },
    { title: "Add Banking", path: "/toursbanking", reactIcon: <BsBank2 /> },
    { title: "Tickets", path: "/tickets", reactIcon: <ImTicket /> },
    {
      title: "Payment Gateway",
      path: "/paymentgateway",
      reactIcon: <FaMoneyCheck />,
    },
  ];

  const LiveLineRoutes = [
    { title: "Dashboard", path: "/", icon: "bi-grid" },
    {
      title: "Live Stream/Line",
      path: "/matchCreation",
      reactIcon: <LuCopyPlus />,
    },
  ];

  const DesignerRoutes = [
    { title: "Dashboard", path: "/", icon: "bi-grid" },
    {
      title: "Offline Broadcasting Notifications",
      path: "/broadcasting",
      reactIcon: <BsBroadcast />,
    },
  ];

  let routes;
  if (register_id === "Company") {
    routes = sidebar;
  } else if (register_id === "AccountTeam") {
    routes = AccoutentRoutes;
  } else if (register_id === "DesignerTeam") {
    routes = DesignerRoutes;
  } else if (register_id === "LineLineTeam") {
    routes = LiveLineRoutes;
  }

  const currentUrl = window.location.href;
  const contains = currentUrl.includes("/usertransaction/");

  const [editPopup, setEditPopup] = useState(false);
  const handleEdit = () => {
    setEditPopup(!editPopup);
    console.log("Gangavva");
  };

  return (
    <div className="d-flex justify-content-between flex-column sidebar-bg th-color p-1 vh-100">
      <div className="sidebar">
        <Link
          to="/login"
          className="d-flex align-items-center justfy-content-center p-2 cursor-pointer"
        >
          <img
            src={contains ? "../assets/ssevenLogo.png" : Images.SsevenLogo}
            className="sseven-logo-size"
            alt="header"
          />
        </Link>
        <hr className="th-color mt-2"></hr>
        <div className="scroll-side-bar">
          <ul className="nav nav-pills flex-column mt-2 nav-width">
            {routes?.map(
              (
                {
                  path,
                  title,
                  icon,
                  reactIcon,
                  childMenu,
                  ariaControls,
                  dataBsTarget,
                },
                index
              ) => (
                <div className="m-1" key={index}>
                  <li
                    className={
                      location?.pathname === path
                        ? "active nav-item p-2"
                        : "nav-item p-2"
                    }
                    key={path}
                  >
                    <Link
                      className="text-decoration-none"
                      to={path}
                      data-bs-toggle={childMenu && "collapse"}
                      data-bs-target={dataBsTarget}
                      aria-expanded="false"
                      aria-controls={ariaControls}
                    >
                      <div className="w-100 p-1 th-color medium-font">
                        <i className={`bi me-3 medium-font ${icon}`}>
                          {reactIcon && reactIcon}
                        </i>
                        <span className="th-color medium-font">{title}</span>
                      </div>
                    </Link>
                  </li>
                  {childMenu && (
                    <div
                      className="collapse th-color secong-sidebar-bg"
                      id={ariaControls}
                    >
                      {childMenu.map(({ path, title, icon, reactIcon }) => (
                        <li
                          className={
                            location?.pathname === path
                              ? "active nav-item p-2 my-1"
                              : "nav-item p-2 my-1"
                          }
                          key={path}
                        >
                          <Link to={path} className="text-decoration-none">
                            <div className="w-100 p-1 th-color medium-font">
                              <i
                                className={`bi me-3 th-color medium-font ${icon}`}
                              >
                                {reactIcon && reactIcon}
                              </i>
                              <span className="th-color medium-font">
                                {title}
                              </span>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </div>
                  )}
                </div>
              )
            )}
          </ul>
        </div>
      </div>
      <div>
        <hr className="text-white mt-2"></hr>
        <div className="nav-item p-2 d-flex align-items-center cursor-pointer">
          <div className="d-flex align-items-center p-1 text-decoration-none text-white">
            <i className="d-flex align-items-center bi bi-person-circle me-3 fs-5"></i>
            <span className="text-white fs-8">Company</span>
            <span className="ms-2" onClick={handleEdit}>
              <MdOutlineEdit className="clr-white" />
            </span>
          </div>
        </div>
      </div>
      <EditPopup show={editPopup} setShow={setEditPopup} />
    </div>
  );
}

export default Sidebar;
