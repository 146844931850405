import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa6";
import Table from "../table/Table";
import { MdEdit } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { MdBlock } from "react-icons/md";
import DeletePopup from "./../broadcasting/DeletePopup";
import BlockPopup from "./BlockPopup";
import AddPaymentGateway from "./AddPaymentGateway";

function PaymentGateway() {
  const closeRef = useRef(null);

  const [countryOption, setCountryOption] = useState(false);
  const countryDropDown = () => {
    setCountryOption(!countryOption);
  };
  const [gatewayoption, setGatewayOption] = useState(false);
  const gatewayDropDown = () => {
    setGatewayOption(!gatewayoption);
  };
  const [detailsOption, setDetailsOption] = useState(false);
  const detailsDropDown = () => {
    setDetailsOption(!detailsOption);
  };

  const [currency, setCurrency] = useState(false);
  const currenciesNames = () => {
    setCurrency(!currency);
  };

  const [statuses1, setStatuses1] = useState(["Active", "Inactive"]);
  const [statuses2, setStatuses2] = useState(["Active", "Inactive"]);
  const [statuses3, setStatuses3] = useState(["Active", "Inactive"]);

  const toggleStatus1 = (index) => {
    const updatedStatuses = [...statuses1];
    updatedStatuses[index] =
      updatedStatuses[index] === "Active" ? "Inactive" : "Active";
    setStatuses1(updatedStatuses);
  };

  const toggleStatus2 = (index) => {
    const updatedStatuses = [...statuses2];
    updatedStatuses[index] =
      updatedStatuses[index] === "Active" ? "InActive" : "Active";
    setStatuses2(updatedStatuses);
  };

  const toggleStatus3 = (index) => {
    const updatedStatuses = [...statuses3];
    updatedStatuses[index] =
      updatedStatuses[index] === "Active" ? "inActive" : "Active";
    setStatuses3(updatedStatuses);
  };

  const [paymnetDelPopup, setPaymentDelPopup] = useState(false);
  const handleDelPopup = () => {
    setPaymentDelPopup(!paymnetDelPopup);
    console.log("del");
  };

  const [openBlockPopup, setOpenBlockPopup] = useState();
  const handleblockPayment = () => {
    setOpenBlockPopup(!openBlockPopup);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const handleAddPayment = (editing = false) => {
    setAddPayment(!addPayment);
    setIsEditing(editing);
  };

  const gatewayCols = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">GATEWAY NAME</div>
        </div>
      ),
      field: "gateway",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">PAYMENT DETAILS</div>
        </div>
      ),
      field: "paymentdetails",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">LAST UPDATE</div>
        </div>
      ),
      field: "lastupdtae",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">COUNTRY</div>
        </div>
      ),
      field: "country",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">CURRENCY</div>
        </div>
      ),
      field: "currency",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">STATUS</div>
        </div>
      ),
      field: "status",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10">ACTION</div>
        </div>
      ),
      field: "action",
    },
  ];

  const gatewayData = [
    {
      gateway: <div className="clr-yellow">Phone Pe</div>,
      paymentdetails: (
        <div className="d-flex flex-column">
          <div>867654378962</div>
          <div>saikumar icici bank</div>
        </div>
      ),
      lastupdtae: "14/08/2024",
      country: "India",
      currency: "INR",
      status: (
        <div
          className={`px-2 py-1 ${
            statuses1[0] === "Active" ? "clr-green green-bg" : "red-bg clr-red"
          }`}
          onClick={() => toggleStatus1(0)}
          style={{ cursor: "pointer" }}
        >
          {statuses1[0]}
        </div>
      ),
      action: (
        <div className="d-flex flex-between th-color pointer">
          <span className="font-20 mx-2">
            <MdEdit onClick={() => handleAddPayment(true)} />
          </span>
          <span className="font-20">
            <MdDeleteOutline onClick={handleDelPopup} />
          </span>
          <span className="font-20 mx-2">
            <MdBlock onClick={handleblockPayment} />
          </span>
        </div>
      ),
    },
    {
      gateway: <div className="clr-yellow">Google Pay</div>,
      paymentdetails: (
        <div className="d-flex flex-column">
          <div>867654378962</div>
          <div>saikumar icici bank</div>
        </div>
      ),
      lastupdtae: "14/08/2024",
      country: "India",
      currency: "INR",
      status: (
        <div
          className={`px-2 py-1 ${
            statuses2[0] === "Active" ? "clr-green green-bg" : "red-bg clr-red"
          }`}
          onClick={() => toggleStatus2(0)}
          style={{ cursor: "pointer" }}
        >
          {statuses2[0]}
        </div>
      ),
      action: (
        <div className="d-flex flex-between th-color pointer">
          <span className="font-20 mx-2">
            <MdEdit onClick={() => handleAddPayment(true)} />
          </span>
          <span className="font-20">
            <MdDeleteOutline onClick={handleDelPopup} />
          </span>
          <span className="font-20 mx-2">
            <MdBlock onClick={handleblockPayment} />
          </span>
        </div>
      ),
    },
    {
      gateway: <div className="clr-yellow">QR Code</div>,
      paymentdetails: (
        <div className="d-flex flex-column">
          <div>867654378962</div>
          <div>saikumar icici bank</div>
        </div>
      ),
      lastupdtae: "14/08/2024",
      country: "India",
      currency: "INR",
      status: (
        <div
          className={`px-2 py-1 ${
            statuses3[0] === "Active" ? "clr-green green-bg" : "red-bg clr-red"
          }`}
          onClick={() => toggleStatus3(0)}
          style={{ cursor: "pointer" }}
        >
          {statuses3[0]}
        </div>
      ),
      action: (
        <div className="d-flex flex-between th-color pointer">
          <span className="font-20 mx-2">
            <MdEdit onClick={() => handleAddPayment(true)} />
          </span>
          <span className="font-20">
            <MdDeleteOutline onClick={handleDelPopup} />
          </span>
          <span className="font-20 mx-2">
            <MdBlock onClick={handleblockPayment} />
          </span>
        </div>
      ),
    },
  ];

  const [selectedDetail, setSelectedDetail] = useState(null);

  const detailsdata = [
    {
      username: "username1",
      account: "000986676762",
      ifsc: "sbi87766",
      bank: "sbi",
    },
    {
      username: "username2",
      account: "000986676763",
      ifsc: "sbi87767",
      bank: "sbi",
    },
  ];

  const handleChange = (event) => {
    setSelectedDetail(event.target.value);
  };

  const countries = ["India", "Dubai", "New Zealand","USA","UK","France"];

  const [selectedCountry, setSelectedCountry] = useState("Select");
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setCountryOption(false);
  };

  const gateways = ["UPI", "PhonePe", "Google Pay", "NEFT", "QR Code"];
  const [selectedGateway, setSelectedGateway] = useState("Select");
  const handleGatewaySelect = (gateway) => {
    setSelectedGateway(gateway);
    setGatewayOption(false);
  };

  const currencies = ["Inr", "dollar", "dinam", "dollar", "dinam"];
  const [selectedCurrency, setSelectedCurrency] = useState("Select");
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
    setCurrency(false);
  };


  const countryRef = useRef(null);
  const gatewayRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (countryRef.current && !countryRef.current.contains(event.target)) {
        setCountryOption(false);
      }
      if (gatewayRef.current && !gatewayRef.current.contains(event.target)) {
        setGatewayOption(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="sidebar-bg rounded w-100">
      <div className="p-3">
        <div className="d-flex flex-between">
          <div className="text-white mb-2">Add Payment Gateway</div>
          <div
            className="blue-bg px-3 py-2 clr-white mx-2 cursor-pointer medium-font"
            onClick={handleAddPayment}
          >
            Add +
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="col-2">
            <div className="th-color mb-1 medium-font">Country</div>
            <div className="position-relative">
              <div
                className="py-2 blue-shade-bg w-80 d-flex flex-between th-color" ref={countryRef}
                onClick={countryDropDown}
              >
                <span className="mx-2 small-font">{selectedCountry}</span>
                {countryOption ? (
                  <FaChevronUp className="mx-2" />
                ) : (
                  <FaChevronDown className="mx-2" />
                )}
              </div>
              <div className="mt-1">
                {countryOption && (
                  <div className="blue-shade-bg dropdown-scroll position-absolute w-80 medium-font">
                    {countries.map((country, index) => (
                      <div
                        key={index}
                        className="py-2 border-bottom-white px-1 clr-hover small-font"
                        onClick={() => handleCountrySelect(country)}
                      >
                        {country}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-2">
            <div className="th-color mb-1 medium-font">Gateway</div>
            <div className="position-relative">
              <div
                className="py-2 blue-shade-bg w-80 d-flex flex-between th-color " ref={gatewayRef}
                onClick={gatewayDropDown}
              >
                <span className="mx-2 small-font">{selectedGateway}</span>
                {gatewayoption ? (
                  <FaChevronUp className="mx-2" />
                ) : (
                  <FaChevronDown className="mx-2" />
                )}
              </div>
              <div className="mt-1">
                {gatewayoption && (
                  <div className="blue-shade-bg dropdown-scroll position-absolute w-80  medium-font ">
                    {gateways.map((gateway, index) => (
                      <div
                        key={index}
                        className="py-2 border-bottom-white px-1 clr-hover small-font"
                        onClick={() => handleGatewaySelect(gateway)}
                      >
                        {gateway}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="th-color mb-1 medium-font">Select Details</div>
            <select
              value={selectedDetail}
              onChange={handleChange}
              className=" blue-shade-bg w-fill th-color w-90 small-font"
            >
              <option value='' className="small-font">Select</option>
              {detailsdata.map((detail, index) => (
                <option className="medium-font" key={index} value={index}>
                  {detail.username} - {detail.bank}
                </option>
              ))}
            </select>
          </div>

          <div className="col-2">
            <div className="blue-bg clr-white px-3 w-80 py-2 ms-4 mt-3 text-center cursor-pointer medium-font">
              Submit
            </div>
          </div>
        </div>

        <div className="d-flex flex-between mt-2 ">
          <div className="th-color medium-font">All Currencies</div>

          <div className="position-relative">
            <div
              className="py-2 blue-shade-bg w-90 d-flex flex-between th-color pointer"
              onClick={currenciesNames}
            >
              <span className="mx-2 medium-font">{selectedCurrency}</span>
              {currency ? (
                <FaChevronUp className="mx-2" />
              ) : (
                <FaChevronDown className="mx-2" />
              )}
            </div>
            <div className="mt-1">
              {currency && (
                <div className="blue-shade-bg dropdown-scroll position-absolute w-90 medium-font pointer">
                  {currencies.map((currency, index) => (
                    <div
                      key={index}
                      className="py-2 border-bottom-white px-1 clr-hover small-font"
                      onClick={() => handleCurrencySelect(currency)}
                    >
                      {currency}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <Table columns={gatewayCols} data={gatewayData} />
      </div>
      <DeletePopup
        show={paymnetDelPopup}
        setShow={setPaymentDelPopup}
        title={"Payment Gateway"}
      />
      <BlockPopup
        show={openBlockPopup}
        setShow={setOpenBlockPopup}
        title="pament gateway"
      />
      <AddPaymentGateway
        showPayment={addPayment}
        setShowPayment={setAddPayment}
        isEditing={isEditing}
      />
    </div>
  );
}

export default PaymentGateway;
