import React, { useState } from "react";
import Table from "../table/Table";

function ChipsReportHeader() {
  
  const MYSTATEMENT_DETAILS = [
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
    {
      name: "Srinivas",
      totalamount: "10000",
      paidamount: "10000",
      balanceamount: <div className="red-text">0.00</div>,
    },
  ];

  const cols = [
    {
      header: "COMPANY NAME",
      field: "nameAndRole",
    },

    {
      header: "TOTAL AMOUNT",
      field: "totalamount",
    },

    {
      header: "PAID AMOUNT",
      field: "paidamount",
    },
    {
      header: "BALANCE AMOUNT",
      field: "balanceamount",
    },
  ];
  const footer = [
    {
      header: "Total",
      field: "nameAndRole",
    },

    {
      header: "50000",
      field: "totalamount",
    },

    {
      header: "600000",
      field: "paidamount",
    },
    {
      header: "700000",
      field: "balanceamount",
    },
  ];

  const modifiedMystatementDetails = MYSTATEMENT_DETAILS.map((item) => ({
    ...item,
    nameAndRole: (
      <div>
        {item?.name} <br /> <span className="role-color">{item?.role}</span>
      </div>
    ),
  }));
  const totalAmount = MYSTATEMENT_DETAILS.reduce(
    (sum, item) => sum + parseFloat(item.totalamount),
    
  );
  return (
    <div>
      <div>
        <Table
          columns={cols}
          data={modifiedMystatementDetails}
          footer={footer}
       
        />
      </div>
    </div>
  );
}

export default ChipsReportHeader;
