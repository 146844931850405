import { Col, Container, Modal, Row } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { PiArrowsOutLight } from "react-icons/pi";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
import {
  GET_ADMIN_PACKAGE_REQUEST,
  GET_SETTINGS_DATA,
} from "../../config/endpoints";
import { call } from "../../config/axios";
import ShowImagePopup from "./ShowImagePopup";

function GatewaysViewPopUp(props) {
  const {
    show,
    setShow,
    transactionData = {},
    isProcessing,
    handleSuccessfullPopup,
    status,
    requestedPackages,
  } = props;

  const [submitModal, setSubmitModal] = useState(false);
  const handleSubmitModal = () => {
    setSubmitModal(!submitModal);
  };

  const [isPackageListOpen, setIsPackageListOpen] = useState(false);
  const [isSpecialOfferOpen, setIsSpecialOfferOpen] = useState(false);

  const togglePackageList = () => {
    setIsPackageListOpen(!isPackageListOpen);
    if (isSpecialOfferOpen) setIsSpecialOfferOpen(false);
  };

  const toggleSpecialOffer = () => {
    setIsSpecialOfferOpen(!isSpecialOfferOpen);
    if (isPackageListOpen) setIsPackageListOpen(false);
  };
  const [showScreenshotImg, setShowScreenshotImg] = useState(false);
  const handleShowImg = () => {
    setShowScreenshotImg(true);
  };
  const [saleTicket, setSaleTicket] = useState([]);
  const [rejectionDropDown, setRejectionDropDown] = useState([]);

  const [acceptClick, setAcceptClick] = useState(false);

  const getAllsaleTickets = async () => {
    await call(GET_ADMIN_PACKAGE_REQUEST, { register_id: "company" })
      .then((res) => {
        setSaleTicket(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllsaleTickets();
  }, []);

  const handelChange = (e) => {
    setSaleTicket({
      ...saleTicket,
      [e.target.name]: e.target.value,
    });
  };

  const getAllRejections = async () => {
    await call(GET_SETTINGS_DATA, { p_id: "REJECT-REASON" })
      .then((res) => {
        setRejectionDropDown(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllRejections();
  }, []);

  const tableData = transactionData?.requested_packages?.map((obj) => {
    return {
      packages: obj?.package_name,
      purchase: obj?.no_of_packages,
      price: obj?.package_cost,
      returnpkg: obj?.return_packages || 0,
      returnhrs: obj?.return_hrs || 0,
    };
  });
  const returnPackages = transactionData?.summary?.return_package_list?.map(
    (obj) => {
      return {
        packages: obj?.package_name,
        purchase: obj?.no_of_packages,
        price: obj?.no_of_packages * obj.cost,
        returnpkg: 0,
        returnhrs: obj?.return_hrs || 0,
      };
    }
  );

  const totalNoPackages = transactionData?.requested_packages?.reduce(
    (acc, obj) => acc + (obj?.no_of_packages || 0),
    0
  );
  const totalNoPackagesCost = transactionData?.requested_packages?.reduce(
    (acc, obj) => acc + (obj?.package_cost || 0),
    0
  );
  const totalNoReturnPackages =
    transactionData?.summary?.return_package_list?.reduce(
      (acc, obj) => acc + (obj?.selected_no_of_packages || 0),
      0
    );
  const totalNoReturnPackagesCost =
    transactionData?.summary?.return_package_list?.reduce(
      (acc, obj) => acc + (obj?.cost * obj?.selected_no_of_packages || 0),
      0
    );
  const totalPackagesDiscount = transactionData?.requested_packages?.reduce(
    (acc, obj) =>
      acc + obj.discount / transactionData?.requested_packages?.length,
    0
  );
  const afterPackDiscountTotalCost =
    totalNoPackagesCost - (totalNoPackagesCost * totalPackagesDiscount) / 100;
  const userDiscountCost =
    totalNoPackagesCost -
    (totalNoPackagesCost * transactionData?.summary?.user_discount) / 100;
  console.log(requestedPackages, "===>requestedPackages");

  const [isOpenSpecialofferList, setIsOpenSpecialofferList] = useState(false);
  const toggleOffer = () => {
    setIsOpenSpecialofferList(!isOpenSpecialofferList);
  };
  return (
    <div className="modal fade bd-example-modal-lg container mt-5">
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 p-4 flex-columnn relative-position">
            <div className="d-flex justify-content-start">
              <div className="w-25 small-font d-flex align-items-center ">
                superadmin2000
                <button className="yellow-btn rounded clr-orange mx-2 px-2 py-1">
                  superadmin
                </button>
                <div className="rounded-pill small-font px-3 py-1 completed-btn">
                  Deposit
                </div>
              </div>
            </div>
            <div className="d-flex flex-row w-100  small-font input-btn-bg rounded align-items-center justify-content-between my-2 px-2 py-2">
              <div className="font-grey">Trx ID</div>
              <div>trx-2036345346745</div>
            </div>
            <div className="d-flex flex-row w-100  small-font input-btn-bg rounded align-items-center justify-content-between my-2 px-2 py-2">
              <div className="font-grey">Reference ID</div>
              <div>reg-20563454576</div>
            </div>
            <div className="d-flex w-100">
              <div className="col-4">
                <div className="d-flex flex-row w-100  small-font input-btn-bg rounded align-items-center justify-content-between my-2 px-2 py-2">
                  <div className="font-grey">Currency</div>
                  <div className="clr-yellow">INR</div>
                </div>
              </div>
              <div className="col-4 px-1">
                <div className="d-flex flex-row w-100  small-font input-btn-bg rounded align-items-center justify-content-between my-2 px-2 py-2">
                  <div className="font-grey white-space">Curr Amt</div>
                  <div className="clr-yellow">100</div>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex flex-row w-100  small-font input-btn-bg rounded align-items-center justify-content-between my-2 px-2 py-2">
                  <div className="font-grey">Amt in INR</div>
                  <div className="clr-yellow">1500</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column px-2 input-btn-bg rounded">
              <div className="d-flex flex-row justify-content-between small-font  all-none  align-items-center justify-content-between my-1 ">
                <div className="font-grey">Amount</div>
                <div>2000</div>
              </div>
              <div className="d-flex flex-row justify-content-between small-font  all-none  align-items-center justify-content-between my-1 ">
                <div className="font-grey">Payment Method</div>
                <div>UPI</div>
              </div>
              <div className="d-flex flex-row justify-content-between small-font  all-none  align-items-center justify-content-between my-1 ">
                <div className="font-grey">From</div>
                2024-10-30
              </div>
              <div className="d-flex flex-row justify-content-between small-font  all-none  align-items-center justify-content-between my-1 ">
                <div className="font-grey">To</div>
                {localStorage.getItem("register_id")}
              </div>
              <div className="d-flex flex-row justify-content-between small-font  all-none  align-items-center justify-content-between my-1 ">
                <div className="font-grey">Time</div>
                <div>
                  2024-11-10
                  10:30:22
                </div>
              </div>
            </div>
            <div className="w-100 my-1 relative-position">
              {console.log(transactionData?.summary?.transaction_img)}
              <img
                className="w-100 h9vh rounded"
                src={process.env.PUBLIC_URL + "./assets/dog_imge.jpg"}
                alt=""
              />
              <PiArrowsOutLight
                className="zoom-icon"
                onClick={() => handleShowImg()}
              />
            </div>
            <Dropdown
              size="lg"
              className="user-dropdown-toggle custom-button-drop small-font mt-2"
            >
              <Row>
                <Col>
                  <Dropdown.Toggle className="w-100">
                    <div className="w-100 d-flex align-itens-center justify-content-between p-1">
                      <div className="font-grey">Package List</div>
                      <span style={{ float: "left" }} className="clr-yellow">
                        View
                      </span>
                    </div>
                  </Dropdown.Toggle>
                </Col>
              </Row>
              <Dropdown.Menu className="w-100 custom-menu-item px-1">
                <table className="w-100 match-position-table small-font clr-white">
                  <thead>
                    <tr className="text-center">
                      <td>Package</td>
                      <td>Purchase</td>
                      <td>Price</td>
                      <td>Return Pkg</td>
                      <td>Return Hrs</td>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{item.packages}</td>
                        <td>{item.purchase}</td>
                        <td>{item.price}</td>
                        <td>{item.returnpkg}</td>
                        <td>{item.returnhrs}</td>
                      </tr>
                    ))}
                    {returnPackages?.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{item.packages}</td>
                        <td>{item.purchase}</td>
                        <td>{item.price}</td>
                        <td>{item.returnpkg}</td>
                        <td>{item.returnhrs}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="small-font justify-content-between w-100"></tfoot>
                </table>
                <Dropdown.Item className="rounded my-2 d-flex flex-row ps-0 pe-0">
                  <div className="d-flex flex-row w-100 justify-content-between small-font">
                    <div className="d-flex flex-column w-50 mx-2 my-1">
                      <div className="d-flex flex-row justify-content-between">
                        <span>Total Purchase</span>
                        <span>
                          {totalNoPackages}= {totalNoPackagesCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Return Pkg</span>
                        <span>
                          {totalNoReturnPackages} ={totalNoReturnPackagesCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Return Hrs</span>
                        <span>26 = 30000</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column w-50 mx-2 my-1">
                      <div className="d-flex flex-row justify-content-between">
                        <span>Discount</span>
                        <span>
                          {totalPackagesDiscount}% ={afterPackDiscountTotalCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Special Offer</span>
                        <span>
                          {transactionData?.summary?.user_discount}% =
                          {userDiscountCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Paid Amount</span>
                        <span>
                          {transactionData?.summary?.final_package_cost}
                        </span>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              size="lg"
              className="user-dropdown-toggle custom-button-drop small-font mt-2"
            >
              <Row>
                <Col>
                  <Dropdown.Toggle className="w-100">
                    <div className="w-100 d-flex align-itens-center justify-content-between p-1">
                      <div className="font-grey">Special Offer</div>
                      <span style={{ float: "left" }} className="clr-yellow">
                        View
                      </span>
                    </div>
                  </Dropdown.Toggle>
                </Col>
              </Row>
              <Dropdown.Menu className="w-100 custom-menu-item px-1">
                <table className="w-100 match-position-table small-font clr-white">
                  <thead>
                    <tr className="text-center">
                      <td>Package</td>
                      <td>Purchase</td>
                      <td>Price</td>
                      <td>Return Pkg</td>
                      <td>Return Hrs</td>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{item.packages}</td>
                        <td>{item.purchase}</td>
                        <td>{item.price}</td>
                        <td>{item.returnpkg}</td>
                        <td>{item.returnhrs}</td>
                      </tr>
                    ))}
                    {returnPackages?.map((item, index) => (
                      <tr key={index} className="text-center">
                        <td>{item.packages}</td>
                        <td>{item.purchase}</td>
                        <td>{item.price}</td>
                        <td>{item.returnpkg}</td>
                        <td>{item.returnhrs}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot className="small-font justify-content-between w-100"></tfoot>
                </table>
                <Dropdown.Item className="rounded my-2 d-flex flex-row ps-0 pe-0">
                  <div className="d-flex flex-row w-100 justify-content-between small-font">
                    <div className="d-flex flex-column w-50 mx-2 my-1">
                      <div className="d-flex flex-row justify-content-between">
                        <span>Total Purchase</span>
                        <span>
                          {totalNoPackages}= {totalNoPackagesCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Return Pkg</span>
                        <span>
                          {totalNoReturnPackages} ={totalNoReturnPackagesCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Return Hrs</span>
                        <span>26 = 30000</span>
                      </div>
                    </div>
                    <div className="d-flex flex-column w-50 mx-2 my-1">
                      <div className="d-flex flex-row justify-content-between">
                        <span>Discount</span>
                        <span>
                          {totalPackagesDiscount}% ={afterPackDiscountTotalCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Special Offer</span>
                        <span>
                          {transactionData?.summary?.user_discount}% =
                          {userDiscountCost}
                        </span>
                      </div>
                      <div className="d-flex flex-row justify-content-between">
                        <span>Paid Amount</span>
                        <span>
                          {transactionData?.summary?.final_package_cost}
                        </span>
                      </div>
                    </div>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div>
              <select
                id="reason"
                name="reason"
                value={saleTicket?.reason || ""}
                onChange={(e) => handelChange(e)}
                className="w-100 small-font input-btn-bg p-2 all-none rounded all-none mt-2 small-font"
              >
                <option value="" className="small-font">
                  Select
                </option>
                {rejectionDropDown?.map(
                  (obj, i) =>
                    obj.active === true && (
                      <option key={i} value={obj.reason} className="small-font">
                        {obj.reason}
                      </option>
                    )
                )}
              </select>
            </div>
            <input
              type="text"
              placeholder="Specify Others"
              className="w-100 small-font input-btn-bg p-2 all-none rounded all-none mt-2"
            ></input>
            {/* {transactionData?.status === "pending" ? (
              <div className="d-flex justify-content-between mt-3 w-100">
                <button
                  type="submit"
                  className="add-button  small-font rounded px-4 py-2 me-2 w-50 all-none"
                  onClick={() =>
                    handleSuccessfullPopup(
                      transactionData?.transaction_id,
                      transactionData.type,
                      "Approved"
                    )
                  }
                  disabled={isProcessing}
                >
                  {isProcessing && status !== "Reject"
                    ? "Processing..."
                    : "Accept"}
                </button>
                <button
                  type="submit"
                  className="deactive-button small-font rounded px-4 py-2 ms-2 w-50 all-none"
                  onClick={() =>
                    handleSuccessfullPopup(
                      transactionData?.transaction_id,
                      transactionData.type,
                      "Reject"
                    )
                  }
                  disabled={isProcessing}
                >
                  {isProcessing && status == "Reject"
                    ? "Processing..."
                    : "Reject"}
                </button>
              </div>
            ) : (
              <div className="text-center font-size-14 fw-600 mt-2">
                You already
                <span
                  className={
                    transactionData.status === "Approved"
                      ? "ps-1 green-clr"
                      : "ps-1 red-clr"
                  }
                >
                  {transactionData.status}
                </span>
              </div>
            )} */}
            <div className="d-flex w-100">
              <div className="col-6">
                <div
                  className="d-flex flex-row w-100  small-font blue-bg rounded align-items-center flex-center my-2 px-2 py-2 pointer"
                  onClick={handleSubmitModal}
                >
                  <div className="font-grey ">Approved</div>
                </div>
              </div>
              <div className="col-6 ps-1">
                <div className="d-flex flex-row w-100  small-font input-btn-bg rounded align-items-center flex-center my-2 px-2 py-2 pointer">
                  <div className="font-grey ">Rejected</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
      </Modal>
      <ShowImagePopup
        transactionData={transactionData}
        showScreenshotImg={showScreenshotImg}
        setShowScreenshotImg={setShowScreenshotImg}
      />
      <MatchSubmitPopup
        header={"Payment Approved Successfully"}
        state={submitModal}
        setState={setSubmitModal}
      />
    </div>
  );
}

export default GatewaysViewPopUp;
