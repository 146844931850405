import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";

const AddPaymentGateway = ({ showPayment, setShowPayment, isEditing }) => {
  const [successPopup, setSuccessPopup] = useState(false);
  const handleSubmitSuccess = () => {
    setSuccessPopup(!successPopup);
  };
  return (
    <div>
      <Modal show={showPayment} onHide={setShowPayment} centered size="lg">
        <div className="sidebar-bg p-4 radius d-flex flex-column th-color">
          <span className="flex-end cursor-pointer">
            <IoClose
              className="font-20"
              onClick={() => setShowPayment(false)}
            />
          </span>
          <div className="flex-center">
            <h5>Add Payment Gateway</h5>
          </div>
          <div className="d-flex w-100 mt-2 align-items-center">
            <div className="col-6 px-2">
              <div className="small-font my-1">Country*</div>
              <select
                className="w-100 small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                name="paymentGateway"
              >
                <option className="small-font">Select</option>
                <option className="small-font">India</option>
                <option className="small-font">UAE</option>
                <option className="small-font">UK</option>
                <option className="small-font">USA</option>
                <option className="small-font">AUS</option>
              </select>
            </div>
            <div className="col-6 px-2">
              <div className="small-font my-1">Currency*</div>
              <select
                className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                name="paymentGateway"
              >
                <option className="small-font">Select</option>
                <option className="small-font">INR</option>
                <option className="small-font">DINAR</option>
                <option className="small-font">BRITISH POUND</option>
                <option className="small-font">DOLLAR</option>
                <option className="small-font">AUS DOLLAR</option>
              </select>
            </div>
          </div>

          <div className="d-flex w-100 mt-2 align-items-center">
            <div className="col-4 px-2">
              <div className="small-font my-1">Select Website*</div>
              <select
                className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                name="paymentGateway"
              >
                <option className="small-font">Select</option>
                <option className="small-font">we2call.com</option>
                <option className="small-font">we2call.com</option>
                <option className="small-font">we2call.com</option>
                <option className="small-font">we2call.com</option>
                <option className="small-font">we2call.com</option>
              </select>
            </div>
            <div className="col-4 px-2">
              <div className="small-font my-1">Payment Gateway*</div>
              <select
                className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                name="paymentGateway"
              >
                <option className="small-font">Select</option>
                <option className="small-font">UPI</option>
                <option className="small-font">Phone Pe</option>
                <option className="small-font">Paytm</option>
                <option className="small-font">Google Pay</option>
                <option className="small-font">BANK</option>
              </select>
            </div>
            <div className="col-4 px-2">
              <div className="small-font my-1">Status*</div>
              <select
                className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                name="paymentGateway"
              >
                <option className="small-font">Select</option>
                <option className="small-font">Active</option>
                <option className="small-font">In-Active</option>
              </select>
            </div>
          </div>
          <div className="px-2 mt-3">
            <div
              className="blue-bg px-4 py-2 flex-center clr-white cursor-pointer"
              onClick={handleSubmitSuccess}
            >{`${
              isEditing === true
                ? "Update Payment Gateway"
                : "Add Payment Gateway"
            }`}</div>
          </div>
        </div>
      </Modal>
      <MatchSubmitPopup
        header={`${
          isEditing === true
            ? "Payment Gateway Update Successfully"
            : "Payment Gateway Successfully Added"
        }`}
        state={successPopup}
        setState={setSuccessPopup}
      />
    </div>
  );
};

export default AddPaymentGateway;
