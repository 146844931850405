import { useState } from "react";
import SuperAdminTable from "./SuperAdminTable";
import SuperAdminUsersTable from "./SuperAdminUsersTable";
import UsersBetHistory from "./UsersBetHistory";
import { FaLongArrowAltRight } from "react-icons/fa";

function SuperAdminStatement({ DownLineUSerDetails }) {
  const [superAdminButtons, setSuperAdminButtons] = useState(0);
  const handleSuperAdminsButtons = (index) => {
    setSuperAdminButtons(index);
  };
  const SUPER_ADMIN_BUTTONS = [
    {
      btnName: "Admins",
    },
    {
      btnName: "Users",
    },
    // {
    //   btnName: "Users Bet Hisory",
    // },
  ];

  const footer = [
    {
      header: "TOTAL",
      field: "userName",
    },
    {
      header: "60000",
      field: "pkgAmount",
    },
    
    
    
    {
      header: "700000",
      field: "totalAmount",
    },
    {
      header: "800000",
      field: "paidAmount",
    },
    {
      header: "",
      field: "balanceAmount",
    },
    
  ];
  return (
    <div>
      {DownLineUSerDetails && (
        <div className="mt-2">
          <div className="package-bg rounded">
            <div className="row p-3">
              <div className="col d-flex align-items-center">
                <h6 className="h6 mb-0 font-grey">
                  View Downline Director Srinivas <FaLongArrowAltRight className="mx-2 clr-yellow" />
                 <span className="font-bold fw-700 ">Superadmin2000</span> 
                </h6>
              </div>
              <div className="col-4">
                <div className="row">
                  {SUPER_ADMIN_BUTTONS?.map((item, index) => (
                    <div
                      className="col"
                      key={index}
                      onClick={() => handleSuperAdminsButtons(index)}
                    >
                      <div
                        className={`package-btn-bg medium-font font-white rounded p-2 text-center cursor-pointer ${
                          superAdminButtons === index ? "blue-btn" : ""
                        }`}
                      >
                        {item.btnName}
                      </div>
                    </div>
                  ))}
                  <div className="col">
                    <select className="w-100 small-font font-grey p-2 package-bg rounded outline-none cursor-pointer">
                      <option className="small-font">ALL</option>
                     
                      <option className="small-font">We2Call</option>
                     
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-line" />
            <div>
              {superAdminButtons === 0 && <SuperAdminTable />}
              {superAdminButtons === 1 && <SuperAdminUsersTable />}
              {/* {superAdminButtons === 2 && <UsersBetHistory />} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SuperAdminStatement;
