import React, { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoMicOutline } from "react-icons/io5";
import { IoMicOffOutline } from "react-icons/io5";
import { IoCloseCircleOutline } from "react-icons/io5";
import { IoPauseCircleOutline } from "react-icons/io5";
import DocTable from "./../table/DocTable";
import { Images } from "../../images";
import { FaRegCirclePlay } from "react-icons/fa6";
import { RxEnterFullScreen } from "react-icons/rx";
import MatchFullScreen from "./MatchFullScreen";
import SelectLanguageModal from "./SelectLanguageModal";
import { MdOutlineKeyboardVoice } from "react-icons/md";

const LineActive = () => {
  const [seriesOption, setSeriesOption] = useState(false);

  const [matchOption, setMatchOption] = useState(false);

  const colsLineActive = [
    {
      header: "NAME",
      field: "name",
    },
    {
      header: "ROLE",
      field: "role",
    },
    {
      header: "IP ADDRESS",
      field: "ipaddress",
    },
    {
      header: "NO OF LOGIN",
      field: "nooflogin",
    },
    {
      header: "LOCATION",
      field: "location",
    },
    {
      header: "ACTION",
      field: "action",
    },
  ];

  const LineactiveData = [
    {
      name: "user1",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user2",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user2",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user1",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user2",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user2",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user1",
      role: <div className="clr-yellow">user</div>,
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user2",
      role: "user",
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
    {
      name: "user2",
      role: "user",
      ipaddress: "1.567544",
      nooflogin: "2",
      location: "Hyd-India",
      action: (
        <div className="d-flex flex-between th-color ">
          <IoCloseCircleOutline className="font-20 mx-2 " />
          <IoPauseCircleOutline className="font-20 " />
        </div>
      ),
    },
  ];

  const [micOnOff, setMicOnOff] = useState(false);
  const handleMic = () => {
    setMicOnOff(!micOnOff);
  };

  const [showLive, setShowLive] = useState(false);
  const handleLiveModal = () => {
    setShowLive(!showLive);
  };

  const [languageModal, setLanguageModal] = useState(false);
  const handleLangModal = () => {
    setLanguageModal(!languageModal);
  };

  const [selectedSeries, setSelectedSeries] = useState("Select");

  const seriesOptions = [
    "IndiaSeries",
    "DubaiSeries",
    "NewZealandSeries",
    "IndiaSeries",
    "DubaiSeries",
    "NewZealandSeries",
  ];

  const toggleSeriesDropdown = () => {
    setSeriesOption((prev) => !prev);
    setMatchOption(false);
  };

  const handleSeriesSelect = (series) => {
    setSelectedSeries(series);
    setSeriesOption(false);
  };

  const [selectedMatch, setSelectedMatch] = useState("Select");

  const matchOptions = [
    "India vs Aus",
    "USA vs Africa",
    "NewZealand vs India",
    "IndiaSeries",
    "DubaiSeries",
    "NewZealandSeries",
  ];

  const toggleMatchDropdown = () => {
    setMatchOption((prev) => !prev);
    setSeriesOption(false);
  };

  const handleMatchSelect = (match) => {
    setSelectedMatch(match);
    setMatchOption(false);
  };

  const [selectedUser, setSelectedUser] = useState("Users");
  const [usersOption, setUsersOption] = useState(false);

  const usersList = ["User1", "User2", "User3", "User4", "User5", "User6"];

  const toggleUsersDropdown = () => {
    setUsersOption((prev) => !prev);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setUsersOption(false);
  };

  const dropDownRef = useRef(null);

  useEffect(() => {
    const handleClickoutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setSeriesOption(false);
        setMatchOption(false);
      }
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setSeriesOption(false);
        setMatchOption(false);
      }
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setUsersOption(false);
      }
    };
    document.addEventListener("mousedown", handleClickoutside);

    return () => {
      document.removeEventListener("mousedown", handleClickoutside);
    };
  }, []);

  const [isMic, setIsMic]=useState(false);
  const handleVoiceMic=()=>{
    setIsMic(!isMic);
  }
  return (
    <div className="p-3">
      <div className="th-color large-font">
        Live Stream - Line - Line Active
      </div>
      <div className="sidebar-bg mt-2 br-5">
        <div className="d-flex align-items-center th-color p-3">
          <div className="col-2">
            <div className="th-color mb-1 small-font">Series</div>
            <div className="position-relative">
              <div
                className="py-2 blue-shade-bg w-80 d-flex flex-between th-color small-font pointer"
                ref={dropDownRef}
                onClick={toggleSeriesDropdown}
              >
                <span className="mx-2">{selectedSeries}</span>
                {seriesOption ? (
                  <FaChevronUp className="mx-2" />
                ) : (
                  <FaChevronDown className="mx-2" />
                )}
              </div>
              <div className="mt-1">
                {seriesOption && (
                  <div className="blue-shade-bg dropdown-scroll position-absolute w-80  small-font">
                    {seriesOptions.map((series, index) => (
                      <div
                        key={index}
                        className="py-2 border-bottom-white px-1 clr-hover"
                        onClick={() => handleSeriesSelect(series)}
                      >
                        {series}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="blue-shade-bg"></div>
          <div className="col-2 mx-1">
            <div className="small-font th-color mb-1">Match</div>
            <div className="position-relative">
              <div
                className="py-2 blue-shade-bg w-80 d-flex flex-between th-color small-font pointer"
                ref={dropDownRef}
                onClick={toggleMatchDropdown}
              >
                <span className="mx-2">{selectedMatch}</span>
                {matchOption ? (
                  <FaChevronUp className="mx-2" />
                ) : (
                  <FaChevronDown className="mx-2" />
                )}
              </div>
              <div className="mt-1">
                {matchOption && (
                  <div className="blue-shade-bg dropdown-scroll position-absolute w-80  small-font">
                    {matchOptions.map((match, index) => (
                      <div
                        key={index}
                        className="py-2 border-bottom-white px-1 clr-hover"
                        onClick={() => handleMatchSelect(match)}
                      >
                        {match}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-2 me-3">
            <div className="blue-bg clr-white px-3 py-2 mx-3 mt-3 text-center small-font cursor-pointer">
              Submit
            </div>
          </div>

          <div className="d-flex w-100 align-items-center mb-1">
            <div className="col-4">
              <div className="th-color mb-1 medium-font">Your Language</div>
              <div className="">
                <div className="py-2 blue-shade-bg d-flex flex-between th-color small-font ">
                  <span className="mx-2">English</span>
                </div>
              </div>
            </div>
            <div className="col-4 mx-3 mt-4">
              <div className="">
                <div
                  className="py-2 blue-bg w-50 d-flex flex-center th-color small-font text-center pointer"
                  onClick={handleLangModal}
                >
                  <span className="mx-2">Change</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="hr-line" />
        <div className="px-4 pt-3">
          <div className="py-2 px-3 blue-shade-bg th-color d-flex w-100 align-items-center small-font">
            <div className="col-6">
              <span className="medium-font me-1"> Series Name:</span>
              <span className="clr-white mx-1">ICC Women's T20 World Cup</span>
            </div>
            <div className="line-vr mx-2"></div>
            <div className="col-6">
              <span className="medium-font ">Match Name:</span>
              <span className="clr-white mx-1"> India vs SriLanka</span>
            </div>
          </div>
        </div>
        <div className="d-flex w-100 p-3 align-items-center th-color">
          <div className="col-6 px-2 position-relative">
            <div className="">
              <img src={Images.livematch} alt="" className="match-img" />
            </div>
            <div className="live-match-play-icon d-flex flex-column">
              <FaRegCirclePlay className="clr-white font-60" />
            </div>

            <div className="d-flex flex-end flex-between live-match-play-icon2">
              <div className="p-1" onClick={handleVoiceMic}>
                {isMic ? (
                  <MdOutlineKeyboardVoice className="clr-white font-30" />
                ) : (
                  <IoMicOffOutline className="clr-white font-30" />
                )}
              </div>
              <div className="p-1 mx-2" onClick={handleLiveModal}>
                <RxEnterFullScreen className="clr-white font-30" />
              </div>
            </div>
          </div>
          <div className="col-6 px-2 position-relative">
            <div className="d-flex flex-column mic-box">
              <div className="d-flex flex-center">
                {/* <IoMicOutline className="mic-size" /> */}
                <div className="voice">
                  <h1 className="font-20 clr-white">S</h1>
                </div>
              </div>

              <div className="mic-pos" onClick={handleMic}>
                {micOnOff ? (
                  <IoMicOutline className="mic-off-icon p-1" />
                ) : (
                  <IoMicOffOutline className="mic-off-icon p-1" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-between px-2">
          <div className="clr-white p-3 mx-2 medium-font">No of Joiners</div>

          <div className="position-relative mt-2 mx-2">
            <div
              className="py-2 all-box-bg d-flex th-color mx-1 "
              onClick={toggleUsersDropdown}
            >
              <span className="mx-2 px-2 small-font cursor-pointer">
                {selectedUser}
              </span>
              {usersOption ? (
                <FaChevronUp className="mx-2 cursor-pointer small-font" />
              ) : (
                <FaChevronDown className="mx-2 cursor-pointer small-font" />
              )}
            </div>

            <div className="mt-1">
              {usersOption && (
                <div
                  className="blue-shade-bg dropdown-scroll position-absolute w-90 ms-1 small-font cursor-pointer"
                  ref={dropDownRef}
                >
                  {usersList.map((user, index) => (
                    <div
                      key={index}
                      className="py-2 border-bottom-white px-1 clr-hover"
                      onClick={() => handleUserSelect(user)}
                    >
                      {user}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="user-details-table">
            <DocTable
              columns={colsLineActive}
              data={LineactiveData}
              cssClass="lineactive-table"
            />
          </div>
        </div>
      </div>
      <MatchFullScreen showModal={showLive} setShowModal={setShowLive} />
      <SelectLanguageModal
        showLangModal={languageModal}
        setShowLangModal={setLanguageModal}
        heading="Change"
      />
    </div>
  );
};

export default LineActive;
