import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CreateBroadCast from "./CreateBroadCast";
import TextMessage from "./TextMessage";
import PostersAds from "./PostersAds";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function NotificationTextMsg() {
  const headingList = [
    "Notification and Promotion Text Message",
    "Posters & Ads",
  ];
  const [activeReport, setActiveReport] = useState("Notification and Promotion Text Message");
  const handleReport = (report) => {
    setActiveReport(report);
  };
  const navigate = useNavigate();
  return (
    <div className="p-4 w-100">
      <div className="sidebar-bg rounded">
        {/* <div className="button-bg w-100"> */}
        <div className="w-100">
          <div className="d-flex align-items-center w-75 small-font p-3">
            <div className="d-flex justify-content-start pointer">
              <MdOutlineArrowBackIosNew
                className="upload-icon me-3"
                onClick={() => navigate("/broadcasting")}
              />
              {/* <div className="medium-font clr-white ms-3">Create New Broadcast</div> */}
            </div>

            {headingList.map((report, index) => (
              <div
                key={index}
                className={`me-2 ${
                  report === activeReport
                    ? "active-button justify-content-between p-2 px-4 notification-box pointer"
                    : "deactive-msg-button justify-content-between p-2 px-4 pointer"
                }`}
                onClick={() => handleReport(report)}
              >
                {report}
              </div>
            ))}
          </div>
        </div>
        <hr className="hr-line my-2" />

        {activeReport === "Notification and Promotion Text Message" && (
          <TextMessage />
        )}
        {activeReport === "Posters & Ads" && <PostersAds />}
      </div>
    </div>
  );
}

export default NotificationTextMsg;
