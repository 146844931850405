import Totalaccount from "../home/Totalaccount";
import AddWebsitePopup from "../Popups/AddWebsitePopup";
import { useEffect, useState } from "react";
import { GET_ALL_WEBSITES } from "../../config/endpoints";
import { call } from "../../config/axios";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Table from "../table/Table";
function Addwebsites({ selectedRegisterId }) {
  const [allWebsites, setAllWebsites] = useState([]);
  const [filteredWebsites, setFilteredWebsites] = useState([]);
  const [status, setStatus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showAddWebPopup, setShowAddWebPopup] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const handleShowAddWebPopup = () => {
    setShowAddWebPopup(true);
  };
  const searchContent = (value) => {
    setSearchText(value);
    const filteredSearchText = allWebsites.filter((res) =>
      res?.web_url?.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredWebsites(filteredSearchText);
  };

  const cols = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10 fw-600">WEBSITE NAME</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "web_url",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">IS USED</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "location",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">USER NAME</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "user_name",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">ROLE</div>
          {/* <div>
            <div>
              <MdKeyboardArrowUp className="fs-6" />
            </div>
            <MdKeyboardArrowDown className="fs-6 margintop-10" />
          </div> */}
        </div>
      ),
      field: "Role",
      clr: true,
    },
  ];

  const dummyWebsites = [
    {
      web_url: "www.we2call.com",
      location: ["India-Hyderabad"],
      user_name: ["Ashok"],
      Role: <div className="bs-percircl-icon">Director</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    {
      web_url: "www.texch.com",
      location: ["India-Hyderabad"],
      user_name: ["Srinivas"],
      Role: <div className="bs-percircl-icon">SA</div>,
    },
    // Add more dummy data as needed
  ];

  const getAllWebsites = async () => {
    await call(GET_ALL_WEBSITES, { register_id: "company" })
      .then((res) => {
        setAllWebsites(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllWebsites();
  }, [status]);

  return (
    <div className="p-4 w-100">
      <Totalaccount />
      <div className="sidebar-bg rounded">
        <div className="d-flex row">
          <h6 className="h6 font-grey m-0 px-4 pt-3">Add Website</h6>
          <div className=" d-flex justify-conten-around">
            <div className="containaer-fluid ps-2 w-20">
              <form className="d-flex" role="search">
                <input
                  className="search-width m-1 mt-3 p-2 text-white w-100 sidebar-bg borderr rounded medium-font"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchText}
                  onChange={(e) => searchContent(e.target.value)}
                />
              </form>
            </div>
            <div
              className="row justify-content-md-center mt-2 ms-1 p-1"
              onClick={() => handleShowAddWebPopup()}
            >
              <div className="active text-white col-md-auto small-font justify-content-between p-2 px-4 m-1 cursor-pointer ">
                +Add Website
              </div>
            </div>
          </div>
        </div>
        <div className="font-grey ps-2 py-1">Website Names</div>
      </div>
      <AddWebsitePopup
        showAddWebPopup={showAddWebPopup}
        setShowAddWebPopup={setShowAddWebPopup}
        setStatus={setStatus}
      />

      <Table columns={cols} data={dummyWebsites} />
    </div>
  );
}

export default Addwebsites;
