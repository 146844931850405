import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import { Modal } from 'react-bootstrap';
import MatchSubmitPopup from '../../matchpopups/MatchSubmitPopup';

const BlockPopup = ({show,setShow,title}) => {
    const [successPopup, setSuccessPopup]=useState(false)
    const submitPopup=()=>{
        setSuccessPopup(!successPopup);

    }
  return (
    <div>
        <Modal show={show} onHide={()=>setShow(false)} centered className='match-declaration-modal'>
            <div className='sidebar-bg radius d-flex flex-column th-color p-2'>
                <span className="flex-end" onClick={()=>setShow(false)}>
                <IoClose className='font-24 cursor-pointer'/>
                </span>
                <div className='flex-center'>
                    <div className=' text-center'>{`Are you want to block this ${title}`}</div>
                </div>
                <div className='flex-center p-2 '>
                    <div className='blue-bgg px-4 py-2 mx-3 cursor-pointer' onClick={submitPopup}>Yes</div>
                    <div className='blue-bgg px-4 py-2 cursor-pointer' onClick={()=>setShow(false)}>No</div>
                </div>

            </div>
        </Modal>
        <MatchSubmitPopup header={"Blocked Successfully"} state={successPopup} setState={setSuccessPopup}/>
    </div>
  )
}

export default BlockPopup;