import Table from "./../table/Table";
import { AiOutlineEye } from "react-icons/ai";

function SuperAdminUsersTable() {
  const SUPER_ADMIN_USERS_ROW_DATA = [
    {
      userName: (
        <div className="d-flex flex-column">
          <div className=" cursor-pointer">Srinivas</div>
          <div className="role-color">User</div>
        </div>
      ),
      buySportsChips: 20000000,
      withdrawChips: <div className="role-color">500000</div>,
      exposer: <div className="font-orange">500000</div>,
      walletBalance: <div className="role-color">500000</div>,
      withdrawWallet: <div className="role-color">500000</div>,
      profitLoss: <div className="font-green">500000</div>,
      viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
    },
    {
      userName: (
        <div className="d-flex flex-column">
          <div className=" cursor-pointer">Srinivas</div>
          <div className="role-color">User</div>
        </div>
      ),
      buySportsChips: 20000000,
      withdrawChips: <div className="role-color">500000</div>,
      exposer: <div className="font-orange">500000</div>,
      walletBalance: <div className="role-color">500000</div>,
      withdrawWallet: <div className="role-color">500000</div>,
      profitLoss: <div className="font-green">500000</div>,
      viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
    },
    {
      userName: (
        <div className="d-flex flex-column">
          <div className=" cursor-pointer">Srinivas</div>
          <div className="role-color">User</div>
        </div>
      ),
      buySportsChips: 20000000,
      withdrawChips: <div className="role-color">500000</div>,
      exposer: <div className="font-orange">500000</div>,
      walletBalance: <div className="role-color">500000</div>,
      withdrawWallet: <div className="role-color">500000</div>,
      profitLoss: <div className="font-green">500000</div>,
      viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
    },
  ];
  const SUPER_ADMIN_USERS_COLUMN_DATA = [
    {
      header: "USER NAME/ROLE",
      field: "userName",
    },
    
    {
      header: "WALLET BALANCE",
      field: "walletBalance",
    },
    {
      header: "A(WITHDRAW+WALLET BAL)",
      field: "withdrawWallet",
    },
    {
      header: "WITHDRAW CHIPS(W)SPORTS/CASINO",
      field: "profitLoss",
    },
   
  ];

  const footer = [
    {
      header: "TOTAL",
      field: "userName",
    },
    {
      header: "60000",
      field: "pkgAmount",
    },
    
    
    
    {
      header: "700000",
      field: "totalAmount",
    },
    {
      header: "800000",
      field: "paidAmount",
    },
    {
      header: "",
      field: "balanceAmount",
    },
    
  ];
  return (
    <div>
      <Table
        data={SUPER_ADMIN_USERS_ROW_DATA}
        columns={SUPER_ADMIN_USERS_COLUMN_DATA}
        footer={footer}
      />
      
    
    </div>
  );
}

export default SuperAdminUsersTable;
