import Table from "./../table/Table";
import { AiOutlineEye } from "react-icons/ai";
import { call } from "../../config/axios";
import { useEffect, useState } from "react";
import { PACKAGE_STATEMENT } from "../../config/endpoints";
import DirectorStatement from "./DirectorStatement";

function OwnerStatement() {
  const [Packages, setPackages] = useState([]);
  const [showDownLine, setShowDownLine] = useState(false);
  const getPackages = async () => {
    await call(PACKAGE_STATEMENT, {
      register_id: "company",
    })
      .then((res) => {
        setPackages(res?.data?.data?.records);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackages();
  }, []);

  const OWNER_ROW_DATA = [
    {
      userName: (
        <div
          className="d-flex flex-column"
          onClick={() => setShowDownLine(!showDownLine)}
        >
          <div className=" cursor-pointer">Srinivas</div>
          <div className="role-color">Director</div>
        </div>
      ),
      pkgAmount: 500000,
      buySportsChips: (
        <div className="d-flex flex-column">
          <div>100000</div>
          <div className="bluecolor-text">Fixed</div>
        </div>
      ),
      buyCasinoChips: (
        <div className="d-flex flex-column">
          <div>100000</div>
          <div className="bluecolor-text">5%</div>
        </div>
      ),
      rentFixed: <div className="font-green">5000</div>,
      shareRoyalty: <div className="font-green">5000</div>,
      totalAmount: <div className="font-green">5000</div>,
      paidAmount: <div className="font-green">5000</div>,
      balanceAmount: <div className="role-color">50000</div>,
      viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
    },
    {
      userName: (
        <div
          className="d-flex flex-column"
          onClick={() => setShowDownLine(!showDownLine)}
        >
          <div className=" cursor-pointer">Srinivas</div>
          <div className="role-color">Director</div>
        </div>
      ),
      pkgAmount: 500000,
      buySportsChips: (
        <div className="d-flex flex-column">
          <div>100000</div>
          <div className="bluecolor-text">Fixed</div>
        </div>
      ),
      buyCasinoChips: (
        <div className="d-flex flex-column">
          <div>100000</div>
          <div className="bluecolor-text">5%</div>
        </div>
      ),
      rentFixed: <div className="font-green">5000</div>,
      shareRoyalty: <div className="font-green">5000</div>,
      totalAmount: <div className="font-green">5000</div>,
      paidAmount: <div className="font-green">5000</div>,
      balanceAmount: <div className="role-color">50000</div>,
      viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
    },
    {
      userName: (
        <div
          className="d-flex flex-column"
          onClick={() => setShowDownLine(!showDownLine)}
        >
          <div className=" cursor-pointer">Srinivas</div>
          <div className="role-color">Director</div>
        </div>
      ),
      pkgAmount: 500000,
      buySportsChips: (
        <div className="d-flex flex-column">
          <div>100000</div>
          <div className="bluecolor-text">Fixed</div>
        </div>
      ),
      buyCasinoChips: (
        <div className="d-flex flex-column">
          <div>100000</div>
          <div className="bluecolor-text">5%</div>
        </div>
      ),
      rentFixed: <div className="font-green">5000</div>,
      shareRoyalty: <div className="font-green">5000</div>,
      totalAmount: <div className="font-green">5000</div>,
      paidAmount: <div className="font-green">5000</div>,
      balanceAmount: <div className="role-color">50000</div>,
      viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
    },
  ];
  const OWNER_COLUMN_DATA = [
    {
      header: "USER NAME/ROLE",
      field: "userName",
    },
    {
      header: "PKG AMOUNT",
      field: "pkgAmount",
    },

    {
      header: "TOTAL AMOUNT",
      field: "totalAmount",
    },
    {
      header: "PAID",
      field: "paidAmount",
    },
    {
      header: "BALANCE",
      field: "balanceAmount",
    },
  ];
  const footer = [
    {
      header: "TOTAL",
      field: "userName",
    },
    {
      header: "60000",
      field: "pkgAmount",
    },

    {
      header: "700000",
      field: "totalAmount",
    },
    {
      header: "800000",
      field: "paidAmount",
    },
    {
      header: "900000",
      field: "balanceAmount",
    },
  ];
  return (
    <div>
      <div className="package-bg rounded">
        <div className="row p-3">
          <div className="col d-flex align-items-center">
            <h6 className="h6 mb-0 font-grey">
              Downline Balance Statement/Owner
            </h6>
          </div>
          <div className="col d-flex align-items-center justify-content-end">
            <select className="w-25 small-font font-grey p-2 package-bg rounded outline-none cursor-pointer">
              <option className="small-font">ALL</option>

              <option className="small-font">We2Call</option>
            
            </select>
          </div>
        </div>
        <div>
          <Table
            data={OWNER_ROW_DATA}
            columns={OWNER_COLUMN_DATA}
            footer={footer}
          />
        </div>
      </div>
      <DirectorStatement showDownLine={showDownLine} />
    </div>
  );
}

export default OwnerStatement;
