import Table from "./../table/Table";
import { call } from "../../config/axios";
import { AiOutlineEye } from "react-icons/ai";
import { useEffect, useState } from "react";
import { PACKAGE_STATEMENT } from "../../config/endpoints";
import SuperAdminStatement from "./SuperAdminStatement";

function DirectorStatement({ showDownLine }) {
  const [Packages, setPackages] = useState([]);
  const [DownLineUSerDetails,setDownLineUserDetails]=useState(false)

  const getPackages = async () => {
    await call(PACKAGE_STATEMENT, {
      register_id: "company",
    })
      .then((res) => {
        setPackages(res?.data?.data?.records);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackages();
  }, []);

  const DIRECTOR_COLUMN_DATA = [
    {
      header: "USER NAME/ROLE",
      field: "userName",
    },
    {
      header: "PKG AMOUNT",
      field: "pkgAmount",
    },

    {
      header: "TOTAL AMOUNT",
      field: "totalAmount",
    },
    {
      header: "PAID",
      field: "paidAmount",
    },
    {
      header: "BALANCE",
      field: "balanceAmount",
    },
  ];


  const footer = [
    {
      header: "TOTAL",
      field: "userName",
    },
    {
      header: "60000",
      field: "pkgAmount",
    },
    
    
    
    {
      header: "700000",
      field: "totalAmount",
    },
    {
      header: "800000",
      field: "paidAmount",
    },
    {
      header: "900000",
      field: "balanceAmount",
    },
    
  ];

  const DIRECTOR_ROW_DATA = Packages?.map((item) => ({
    userName: (
      <div className="d-flex flex-column">
        <div className=" cursor-pointer" onClick={()=>setDownLineUserDetails(!DownLineUSerDetails)}>{item?.summary.requester_name}</div>
        <div className="role-color">{item?.summary?.requester_role}</div>
      </div>
    ),
    //buySportsChips: (
    //       <div className="d-flex flex-column">
    //         <div>100000</div>
    //         <div className="bluecolor-text">Fixed</div>
    //       </div>
    //     ),
    //     buyCasinoChips: (
    //       <div className="d-flex flex-column">
    //         <div>100000</div>
    //         <div className="bluecolor-text">5%</div>
    //       </div>
    //     ),
    //rentFixed: <div className="font-green">5000</div>,
    //     shareRoyalty: <div className="font-green">5000</div>,
    //     totalAmount: <div className="font-green">5000</div>,
    pkgAmount: item?.summary?.total_package_cost,
    paidAmount: item?.summary?.final_package_cost,
    balanceAmount:
      item?.summary?.total_package_cost - item?.summary?.final_package_cost,
    viewDownline: <AiOutlineEye className="eye-icon cursor-pointer" />,
  }));
  return (
    <div>
      {showDownLine && (
        <div className="mt-2">
        <div className="package-bg rounded">
          <div className="row p-3">
            <div className="col d-flex align-items-center">
              <h6 className="h6 mb-0 font-grey">
                View Downline Director Srinivas
              </h6>
            </div>
            <div className="col d-flex align-items-center justify-content-end">
              <select className="w-25 small-font font-grey p-2 package-bg rounded outline-none cursor-pointer">
                <option className="small-font">ALL</option>
             
                <option className="small-font">We2Call</option>
             
              </select>
            </div>
          </div>
          <div>
            <Table data={DIRECTOR_ROW_DATA} columns={DIRECTOR_COLUMN_DATA} footer={footer}/>
           
          </div>
        </div>
        </div>
      )}
      <SuperAdminStatement DownLineUSerDetails={DownLineUSerDetails}/>
    </div>
  );
}

export default DirectorStatement;
