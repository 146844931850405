import * as actionTypes from "./actionConstants";

export const setPackageInputData = (data) => {
  return {
    type: actionTypes?.SET_PACKAGE_INPUT_DATA,
    payload:data,
  };
};
export const setNotificationData=(data)=>{
  return{
    type:actionTypes?.SET_NOTIFICATION_DATA,
    payload:data,
  };
};