import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Images } from "../../images";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";

const DeletePopup = ({ show, setShow, title }) => {
  const [successModal, setSuccessModal] = useState(false);
  const submitPopup = () => {
    setSuccessModal(!successModal);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="match-declaration-modal"
      >
        <div className="sidebar-bg th-color p-1 br-5">
          <span className="flex-end">
            <IoClose
              className="font-24 cursor-pointer"
              onClick={() => setShow(false)}
            />
          </span>

          <div className="d-flex flex-column flex-center">
            <div>
              <img src={Images.QuestionMark} alt="" className="h-10vh mb-2" />
            </div>
            <div className="text-center clr-white fw-600">{`Are you sure about to delete this ${title}`}</div>
          </div>
          <div className="d-flex flex-center my-3">
            <div
              className="blue-bgg px-4 py-2 me-4 cursor-pointer"
              onClick={submitPopup}
            >
              Delete
            </div>
            <div
              className="blue-bgg px-4 py-2 cursor-pointer"
              onClick={() => setShow(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>
      <MatchSubmitPopup
        header={"Delete Successfully"}
        state={successModal}
        setState={setSuccessModal}
      />
    </div>
  );
};

export default DeletePopup;
