import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const SelectLanguageModal = ({ showLangModal, setShowLangModal, heading }) => {
  const [seriesOption, setSeriesOption] = useState(false);
  const seriesDropDown = () => {
    setSeriesOption(!seriesOption);
  };
  const [series, setSeries] = useState();
  const seriesList = () => {
    setSeries(!series);
  };
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={showLangModal}
        onHide={() => setShowLangModal(false)}
        centered
        size="sm"
      >
        <div className="sidebar-bg p-1 br-5">
          <div className="d-flex flex-end">
            <IoClose
              className="clr-white font-20"
              onClick={() => setShowLangModal(false)}
            />
          </div>

          <div className="clr-white flex-center medium-font">
            {` ${
              heading === "Change"
                ? "Do you want to change your language"
                : "Select Preferred Langauge you want to Speak"
            } `}
          </div>

          <div class="mt-3">
            <form>
              <div class="mb-3">
                <select
                  className="blue-shade-bg w-90 mx-3 small-font"
                  id="languageSelect"
                >
                  <option selected disabled className="small-font">
                    Select a language
                  </option>
                  <option className="medium-font" value="en">
                    English
                  </option>
                  <option className="medium-font" value="es">
                    Spanish
                  </option>
                  <option value="fr" className="medium-font">
                    French
                  </option>
                  <option value="de" className="medium-font">
                    German
                  </option>
                  <option value="zh" className="medium-font">
                    Chinese
                  </option>
                  <option value="ja" className="medium-font">
                    Japanese
                  </option>
                  <option value="ko" className="medium-font">
                    Korean
                  </option>
                  <option value="hi" className="medium-font">
                    Hindi
                  </option>
                  <option value="ar" className="medium-font">
                    Arabic
                  </option>
                </select>
              </div>

              <div
                className="d-flex flex-center mt-2 clr-white cursor-pointer mb-1"
                onClick={() => {
                  if (heading === "Change") {
                    setShowLangModal(false);
                  } else {
                    navigate("/line-active");
                  }
                }}
              >
                <div className="blue-bg px-3 py-1 medium-font">
                  {heading === "Change" ? "Change Language" : "Submit"}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SelectLanguageModal;
