import React, { useState, useRef } from "react";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
import { Col, Modal, Row } from "react-bootstrap";
import {
  ADD_PAYMENT_GATEWAY,
  UPDATE_PAYMENT_GATEWAY,
  GENERATE_SIGNED_URL,
} from "../../config/endpoints";
import { call } from "../../config/axios";

function AddCountryPopups(props) {
  const ImageBaseUrl = "https://we2-call-images.s3.us-east-2.amazonaws.com";
  const { addCountryOpen, setAddCountryOpen, setStatus, updateGatway } = props;
  const [inputData, setInputData] = useState({});
  const [acceptClick, setAcceptClick] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [signedUrl, setSignedUrl] = useState("");

  const handleAddCountryOpenClose = () => {
    setAddCountryOpen(false);
  };

  const uploadfileInputRef = useRef(null);

  const handleUploadFileSelect = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    generateSignedUrl();
  };

  const onInputChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleCreateOrUpdatePaymentGateway = async () => {
    const payload = {
      register_id: "company",
      uploadImage: `${ImageBaseUrl}/payment-images/${paymentId}.png`,
      ...inputData,
    };

    try {
      if (profileImage && signedUrl) {
        await fetch(signedUrl, {
          method: "PUT",
          body: profileImage,
          headers: {
            "Content-Type": "image/jpeg",
            "cache-control": "public, max-age=0",
          },
        });
      } else {
        const url = updateGatway ? UPDATE_PAYMENT_GATEWAY : ADD_PAYMENT_GATEWAY;
        const res = await call(url, payload);
        if (res.data.statusCode === 200) {
          setStatus((prev) => !prev);
          setAcceptClick(true);
          setTimeout(() => {
            setAcceptClick(false);
          }, 2000);
          setAddCountryOpen(false);
          setInputData({});
        }
      }
    } catch (error) {
      console.error("Error creating or updating payment gateway:", error);
    }
  };

  const generateSignedUrl = async () => {
    const newId = new Date().getTime();
    try {
      const res = await call(GENERATE_SIGNED_URL, {
        register_id: `${newId}`,
        event_type: "user_profile_image",
        folder_name: "payment-images",
      });
      const url = res?.data?.data?.result?.signed_url;
      setSignedUrl(url);
      setPaymentId(newId);
    } catch (err) {
      console.error("Error generating signed URL:", err);
    }
  };
  const successPopup=()=>{
    setAcceptClick(!acceptClick);
  }

  return (
    <div className="modal fade bd-example-modal-lg container mt-5">
      <Modal
        size="lg"
        show={addCountryOpen}
        onHide={handleAddCountryOpenClose}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 text-center mt-3">
            <h6>
              {updateGatway ? "Update Payment Gateway" : "Add Payment Gateway"}
            </h6>
          </div>
        </Modal.Header>
        <Modal.Body className="px-5">
          <Row>
            <Col>
              <div className="small-font my-1">Country Name *</div>
              <input
                type="text"
                placeholder="Enter"
                name="country_name"
                className="w-100 small-font login-inputs input-btn-bg px-2 py-3 rounded all-none"
                value={inputData.country_name || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    onInputChange(e);
                  }
                }}
              />
            </Col>

            <Col>
              <div className="small-font my-1">Currency Name *</div>
              <input
                type="text"
                placeholder="Enter"
                name="currency_name"
                className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                value={inputData.currency_name || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    onInputChange(e);
                  }
                }}
              />
            </Col>
            <Col>
              <div className="small-font my-1">Payment Gateway *</div>
              <select
                name="pg_upi"
                onChange={onInputChange}
                className="w-100 small-font input-btn-bg px-2 py-3 all-none rounded"
              >
                <option className="small-font">
                  {inputData.pg_upi || "Select"}
                </option>
                <option value="phonepe" className="small-font">
                  Phonepay
                </option>
                <option value="gpay" className="small-font">
                  Gpay
                </option>
                <option value="paytm" className="small-font">
                  Paytm
                </option>
                <option value="neft" className="small-font">
                  RTGS
                </option>
                <option value="qr_code" className="small-font">
                  QR Code
                </option>
              </select>
            </Col>
          </Row>

          {/* Conditional fields for specific payment gateways */}
          {(inputData.pg_upi === "phonepe" ||
            inputData.pg_upi === "gpay" ||
            inputData.pg_upi === "paytm") && (
            <Row className="mt-2">
              <Col className="col-4">
                <div className="small-font my-1">Mobile Number*</div>
                <input
                  type="text"
                  placeholder="Enter Mobile Number"
                  name="mobile_number"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                  value={inputData.mobile_number || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      onInputChange(e);
                    }
                  }}
                />
              </Col>
              <Col className="col-4">
                <div className="small-font my-1">UPI ID*</div>
                <input
                  type="text"
                  placeholder="Enter UPI Name"
                  name="pg_name"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                  value={inputData.pg_name || ""}
                  onChange={onInputChange}
                />
              </Col>
            </Row>
          )}
          {inputData.pg_upi === "neft" && (
            <Row className="mt-2">
              <Col className="col-4">
                <div className="small-font my-1">Account Holder Name</div>
                <input
                  type="text"
                  placeholder="Enter Account Holder Name"
                  name="account_holder_name"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                  value={inputData.account_holder_name || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[a-zA-Z\s]*$/.test(value)) {
                      onInputChange(e);
                    }
                  }}
                />
              </Col>
              <Col className="col-4">
                <div className="small-font my-1">Bank Name</div>
                <input
                  type="text"
                  placeholder="Enter Bank Name"
                  name="bank_name"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                  value={inputData.bank_name || ""}
                  onChange={onInputChange}
                />
              </Col>
              <Col className="col-4">
                <div className="small-font my-1">Account Number*</div>
                <input
                  type="text"
                  placeholder="Enter Account Number"
                  name="account_number"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                  value={inputData.account_number || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      onInputChange(e);
                    }
                  }}
                />
              </Col>
              <Col className="col-4">
                <div className="small-font my-1">IFSC Code*</div>
                <input
                  type="text"
                  placeholder="Enter IFSC Code"
                  name="ifsc_code"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                  value={inputData.ifsc_code || ""}
                  onChange={onInputChange}
                />
              </Col>
            </Row>
          )}
          {inputData.pg_upi === "qr_code" && (
            <Row>
              <Col className="col-4">
                <div className="small-font my-1">QR Code*</div>
                <input
                  type="file"
                  placeholder="Upload QR Code"
                  name="uploadCode"
                  ref={uploadfileInputRef}
                  onChange={handleUploadFileSelect}
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-3 all-none rounded"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col className="flex-center">
              <button
                type="submit"
                className="add-button small-font rounded  py-3 my-3 w-30 all-none"
                onClick={()=>{
                  successPopup()
                  handleCreateOrUpdatePaymentGateway()}}
              >
                {updateGatway ? "Update" : "Create"}
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <MatchSubmitPopup
        header={
          updateGatway
            ? "Payment Gateway Updated Successfully"
            : "Payment Gateway Added Successfully"
        }
        state={acceptClick}
        setState={setAcceptClick}
        setStatus={setStatus}
      />
    </div>
  );
}

export default AddCountryPopups;
  