import React from "react";
import Table from "../table/Table";
import { Images } from "../../images";
import DocTable from "../table/DocTable";

function Tours() {
  const TOURS_DETAILS = [
    {
      status: (
        <div className="d-flex align-items-center justify-content-center">
          {/* <img src={Images.TourLogo} className="tour-img-size" alt="" /> */}
          <div className="px-1">Calling Pkg</div>
        </div>
      ),
      tickets: 100,
      sales: "150",
      balance:60,
      profitloss: <div className="fa-fileinvo-doll-icon">1000k</div>,
    },
    {
      status: (
        <div className="d-flex align-items-center justify-content-center">
          {/* <img src={Images.TourLogo} className="tour-img-size" alt="" /> */}
          <div className="px-1">Live Pkg</div>
        </div>
      ),
      tickets: 50,
      sales: "150",
      balance:30,
      profitloss: <div className="fa-fileinvo-doll-icon">1000k</div>,
    },
    {
      status: (
        <div className="d-flex align-items-center justify-content-center">
          {/* <img src={Images.TourLogo} className="tour-img-size" alt="" /> */}
          <div className="px-1">Line Pkg</div>
        </div>
      ),
      tickets: 40,
      sales: "150",
      balance:40,
      profitloss: <div className="red-text">1000k</div>,
    },
    // {
    //   status: (
    //     <div className="d-flex align-items-center justify-content-center">
    //       {/* <img src={Images.TourLogo} className="tour-img-size" alt="" /> */}
    //       <div className="px-1">Live Pkg</div>
    //     </div>
    //   ),
    //   tickets: 50,
    //   sales: "150",
    //   balance:20,
    //   profitloss: <div className="fa-fileinvo-doll-icon">1000k</div>,
    // },
    // {
    //   status: (
    //     <div className="d-flex align-items-center justify-content-center">
    //       {/* <img src={Images.TourLogo} className="tour-img-size" alt="" /> */}
    //       <div className="px-1">Line Pkg</div>
    //     </div>
    //   ),
    //   tickets: 50,
    //   sales: "150",
    //   balance:30,
    //   profitloss: <div className="fa-fileinvo-doll-icon">1000k</div>,
    // },
    // {
    //   status: (
    //     <div className="d-flex align-items-center justify-content-center">
    //       {/* <img src={Images.TourLogo} className="tour-img-size" alt="" /> */}
    //       <div className="px-1">Live Pkg</div>
    //     </div>
    //   ),  
    //   tickets: 50,
    //   sales: "150",
    //   balance:50,
    //   profitloss: <div className="fa-fileinvo-doll-icon">1000k</div>,
    // },
  ];

  const cols = [
    {
      header: "SALE",
      field: "status",
    },
    {
      header: "TOTAL TICKETS",
      field: "tickets",
    },
    {
      header: "TOTAL SALE",
      field: "sales",
    },
    {
      header: "BALANCE",
      field: "balance",
    },
    {
      header: "PROFIT/LOSS",
      field: "profitloss",
      clr: false,
    },
  ];

  const modifiedToursDetails = TOURS_DETAILS.map((item, i) => ({
    ...item,
    profitloss: (
      <div key={i} className="role-color">
        <span className="role-color">{item?.profitloss}</span>
      </div>
    ),
  }));

  return (
    <div className="w-100 sidebar-bg rounded" style={{ height: "100%" }}>
      <div className=" w-100 d-flex align-items-center justify-content-between">
        <div className="medium-font font-weight-bold px-2 p-2 m-1 th-color">
          {/* <span>Tours & Tournaments</span> */}
          <span>Total Profit & Loss</span>
          {/* <span className="bluecolor-text">Sales & Volume</span> */}
        </div>
        <div className="day-button rounded-pill small-font height px-2 p-1 mx-2 clr-green">
        180000
        </div>
        {/* <div className=" d-flex justify-conten-between">
          <div className="w-50 d-flex justify-content-between m-1 text-white">
            <div className="day-button rounded-pill small-font height px-2 p-1 me-1 ">
              5000
            </div>
            <div className="day-button rounded-pill small-font height px-2 p-1">
              600000
            </div>
          </div>
        </div> */}
      </div>
      <div>
        <DocTable columns={cols} data={modifiedToursDetails} />
      </div>
    </div>
  );
}

export default Tours;
