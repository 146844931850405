import React, { useState } from "react";
import StreamingInterface from "./LiveStream";
import LineStream from "./LineStream";

const Creatematch = () => {
  const tabs = ["Live Stream", "Line"];

  const [activeTab, setActiveTab] = useState("Live Stream");
  const handleButtonClick = (btn) => {
    setActiveTab(btn);
  };
  return (
    <div className="p-4">
      <h6 className="th-color">Live Stream/Line</h6>
      <div className="sidebar-bg">
        <div className="d-flex p-3">
          {tabs.map((btn, index) => (
            <div
              key={index}
              className={`tab-button w-14 flex-center medium-font ${
                activeTab === btn ? "active mx-2" : "mx-2"
              }`}
              onClick={() => handleButtonClick(btn)}
            >
              {btn}
            </div>
          ))}
        </div>

        <div className="hr-line"></div>
        <div className="content">
          {activeTab === "Live Stream" ? (
            <StreamingInterface />
          ) : (
            <LineStream />
          )}
        </div>
      </div>
    </div>
  );
};

export default Creatematch;
