import React, { useEffect, useRef, useState } from "react";
import Totalaccount from "../home/Totalaccount";
import { TbWorld } from "react-icons/tb";
import { MdPayment } from "react-icons/md";
import { HiMiniArrowPathRoundedSquare } from "react-icons/hi2";
import { Images } from "../../images";
import { AiOutlineEye } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { BiUser } from "react-icons/bi";
import { IoLocation } from "react-icons/io5";
import TransactionTable from "./TransactionTable";
import PaymentGateway from "./PaymentGateway";
import WebsitesLimit from "./WebsitesLimit";
import { MdKeyboardArrowDown } from "react-icons/md";
import RevenueOfflineShare from "./RevenueOfflineShare";
import { useNavigate, useParams } from "react-router-dom";
import { call } from "../../config/axios";
import { GET_ALL_USERS, USERS_ACTIVE_INACTIVE } from "../../config/endpoints";
import NewWebsitesLimit from "./NewWebsitesLimit";
import AccountData from "../home/AccountData";
import { FaAngleLeft } from "react-icons/fa";

function Usertransaction() {
  const navigate = useNavigate();
  const adminPayload = useParams();
  const account_role = localStorage?.getItem("account_role");
  const [OnlineWebsites, setOnlineWebsites] = useState(true);
  const [transactionData, setTransactionData] = useState("Transaction");
  const [activeDrop, setActiveDrop] = useState(false);
  const [allDirectors, setAllDirectors] = useState();
  const [active, setActive] = useState();

  const uploadfileInputRef = useRef(null);

  const handleActiveButton = (type) => {
    setTransactionData(type);
    setActiveDrop((prev) => !prev);
  };

  const getDirectors = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_USERS, payload)
      .then((res) => {
        console.log(res?.data?.data.Items, "===>RES");
        setAllDirectors(res?.data?.data.Items);
      })
      .catch((err) => console.log(err));
  };

  const handleBlockUnBlock = async (item, active) => {
    const payload = {
      register_id: item,
      active: !active,
    };
    await call(USERS_ACTIVE_INACTIVE, payload)
      .then((res) => {
        setActive(!active);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getDirectors();
  }, [active]);

  const filteredData =
    allDirectors?.length > 0 &&
    allDirectors?.filter((item) => item?.register_id === adminPayload?.id);

  console.log(filteredData[0], "===>filteredData");

  const handleOnline = (item) => {
    setOnlineWebsites(item);
  };
  const currentUrl = window.location.href;
  const contains = currentUrl.includes("/usertransaction/");

  return (
    <div className="p-4 w-100">
      <h6 className="h6 font-grey p-1">
        <FaAngleLeft onClick={() => navigate(-1)} />
        <span className="ps-2">{account_role}-Profile</span>
      </h6>
      <div className="w-100">
        <img
          src={contains ? "../assets/profile-banner.png" : Images.ProfileBanner}
          className="w-100"
          alt="profile_banner"
        />
        <div className="sidebar-bg th-color user-img-bg-br row row-unset p-2">
          <div className="col-5 d-flex">
            {filteredData && filteredData.length > 0 ? (
              <img
                src={Images.PersonImg || filteredData[0]?.profile_image}
                className="w-20 user-margin-top"
                alt="profile_banner"
              />
            ) : (
              <p>No profile image available</p>
            )}
            <input
              type="file"
              ref={uploadfileInputRef}
              style={{ display: "none" }}
              className="login-inputs"
            ></input>
            <div className="row px-2">
              <div className="medium-font d-flex align-items-center">
                {filteredData[0]?.user_name}
              </div>
              <div className="medium-font d-flex justify-content-between align-items-baseline w-75">
                <span>
                  <BiUser />
                  {filteredData?.account_role}
                </span>
                <span>
                  <IoLocation />
                  {filteredData?.country_name}
                </span>
              </div>
            </div>
          </div>
          <div className="col-7 d-flex align-items-center justify-content-end">
            <div className="w-80 d-flex align-items-center justify-content-between">
              <div className="w-30 px-3 py-1 rounded-pill empty-bg-br small-font d-flex align-items-center justify-content-between">
                <span>{filteredData[0]?.user_name}</span>
              </div>
              <div className="px-2 py-1 rounded-pill empty-bg-br small-font d-flex align-items-center justify-content-between">
                <span className="pe-4 ps-2">Password</span>
                <span className="px-3 ps-5 role-color">
                  {/* {filteredData[0]?.password} */}
                </span>
                <span className="role-color">
                  <AiOutlineEye className="eye-icon-size cursor-pointer" />
                </span>
              </div>
              
              <div
                className="active text-white align-items-center small-font p-2 m-1 d-flex justify-content-between align-items-center"
                onClick={() => {
                  handleBlockUnBlock(
                    filteredData?.register_id,
                    filteredData?.active
                  );
                }}
              >
                <TiTick className="eye-icon-size cursor-pointer" />
                {filteredData?.active ? "Active" : "Inactive"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <Totalaccount /> */}
        <AccountData />
      </div>

      <div className="gutter-1rem p-2 mb-2 d-flex head-container">
        <div className="d-flex flex-column">
          <div
            className={`d-flex table-header-box medium-font p-2 px-4 py-2 m-1 align-items-center
                        ${
                          transactionData === "Websites/Limit" && "dropdown-clr"
                        }`}
            onClick={() => handleActiveButton("Websites/Limit")}
          >
            <TbWorld className="medium-font" />
            <div className="medium-font ps-2 cursor-pointer py-1">
              Websites/Limit
            </div>
            {/* <MdKeyboardArrowDown className="fs-6" /> */}
          </div>
          {/* {transactionData === "Websites/Limit" && activeDrop === true && (
            <div className="empty-bg-br d-flex flex-column justify-content-between online-div th-color medium-font">
              <div className="d-flex justify-content-between p-2">
                <span onClick={() => handleOnline("online")}>Online</span>
                <span onClick={() => handleOnline("offline")}>Offline</span>
              </div>
              <hr className="hr-line" />
              {OnlineWebsites === "online" && (
                <div>
                  <div className="d-flex justify-content-between p-2">
                    <span>www.we2call.com</span>
                    <span class="form-check">
                      <input
                        class="form-check-input check-box-input-clr"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between p-2">
                    <span>www.we2call.com</span>
                    <span class="form-check">
                      <input
                        class="form-check-input check-box-input-clr"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-between p-2">
                    <span>www.we2call.com</span>
                    <span class="form-check">
                      <input
                        class="form-check-input check-box-input-clr"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                    </span>
                  </div>
                </div>
              )}
              {OnlineWebsites === "offline" && (
                <div className="d-flex justify-content-between p-2">
                  <span>{filteredData[0]?.website_name}</span>
                  <span class="form-check">
                    <input
                      class="form-check-input check-box-input-clr"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                  </span>
                </div>
              )}
            </div>
          )} */}
        </div>
        <div
          className={`d-flex table-header-box height-fit-content medium-font p-2 px-4 py-2 m-1 align-items-center
                        ${
                          transactionData === "Payment Gateway" &&
                          "dropdown-clr"
                        }`}
          onClick={() => handleActiveButton("Payment Gateway")}
        >
          <MdPayment className="medium-font" />
          <div className="medium-font ps-2 cursor-pointer py-1">Payment Gateway</div>
        </div>
        <div
          className={`d-flex table-header-box height-fit-content medium-font p-2 px-4 py-2 m-1 align-items-center
               ${transactionData === "Transaction" && "dropdown-clr"}`}
          onClick={() => handleActiveButton("Transaction")}
        >
          <HiMiniArrowPathRoundedSquare className="medium-font" />
          <div className="medium-font ps-2 cursor-pointer py-1">Transaction</div>
        </div>
      </div>

      {/* no need  online and ofiiline removed*/}
      {/* {transactionData === "Websites/Limit" && OnlineWebsites === "online" && (
        <WebsitesLimit />
      )}
      {transactionData === "Websites/Limit" && OnlineWebsites === "offline" && (
        <div>
          <RevenueOfflineShare adminPayload={adminPayload} />
          <RevenueOfflineTable />
        </div>
      )} */}

      {transactionData === "Websites/Limit" && <NewWebsitesLimit />}
      {transactionData === "Payment Gateway" && <PaymentGateway />}
      {transactionData === "Transaction" && (
        <TransactionTable selectedRegisterId={adminPayload?.id} />
      )}
    </div>
  );
}

export default Usertransaction;
