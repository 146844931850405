import Table from "../table/Table";
import { useEffect, useState } from "react";
import { PACKAGE_STATEMENT } from "../../config/endpoints";
import { call } from "../../config/axios";

function MystatementPacckage() {
  const [Packages, setPackages] = useState([]);
  const getPackages = async () => {
    await call(PACKAGE_STATEMENT, {
      register_id: "company",
      package_requester_id: "reg-20240229175703556",
    })
      .then((res) => {
        setPackages(res?.data?.data?.records);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPackages();
  }, []);

  const MYSTATEMENTPACKAGE_DETAILS = [
    {
      name: "Srinivas",
      role: "Owner",
      pkgsale: <div className="fa-fileinvo-doll-icon">500000</div>,
      receivedmount: "500000",
      balanceamount: "0.00",
      downlinepkgstock: <div className="red-text">200000</div>,
      totalsalehours: <div className="fa-fileinvo-doll-icon">400000</div>,
      receivedpayment: <div className="red-text">400000</div>,
      hbalamount: <div className="role-color">0.00</div>,
      downlinehourvalue: <div className="role-color">200000</div>,
    },
  ];

  const cols = [
    {
      header: "USER NAME/ROLE",
      field: "nameAndRole",
    },
    {
      header: "PKG SALE",
      field: "pkgsale",
    },

    {
      header: "RECEIVED AMOUNT",
      field: "receivedmount",
    },
    {
      header: "BALANCE AMOUNT",
      field: "balanceamount",
    },
    {
      header: "DOWNLINE PKG STOCK",
      field: "downlinepkgstock",
    },
    {
      header: "TOTAL SALE HOURS",
      field: "totalsalehours",
    },
    // {
    //   header: "RECEIVED PAYMENT",
    //   field: "receivedpayment",
    // },
    // {
    //   header: "H BALANCE AMOUNT",
    //   field: "hbalamount",
    // },
    // {
    //   header: "DOWNLINE HOUR VALUE",
    //   field: "downlinehourvalue",
    // },
  ];
  const footer = [
    {
      header: "TOTAL",
      field: "nameAndRole",
    },
    {
      header: "500000",
      field: "pkgsale",
    },

    {
      header: "6000000",
      field: "receivedmount",
    },
    {
      header: "70000000",
      field: "balanceamount",
    },
    {
      header: "50000",
      field: "downlinepkgstock",
    },
    {
      header: "140 HoursStatement",
      field: "totalsalehours",
    },
    // {
    //   header: "RECEIVED PAYMENT",
    //   field: "receivedpayment",
    // },
    // {
    //   header: "H BALANCE AMOUNT",
    //   field: "hbalamount",
    // },
    // {
    //   header: "DOWNLINE HOUR VALUE",
    //   field: "downlinehourvalue",
    // },
  ];

  const modifiedMystatementpackageDetails = Packages?.map((item) => ({
    ...item,
    nameAndRole: (
      <div>
        {item?.summary?.requester_name} <br />{" "}
        <span className="role-color">{item?.summary?.requester_role}</span>
      </div>
    ),
    pkgsale: item?.summary?.total_package_cost,
    receivedmount: item?.summary?.final_package_cost,
    balanceamount:
      item?.summary?.total_package_cost - item?.summary?.final_package_cost,
    downlinepkgstock: item?.summary?.no_of_packages,
    totalsalehours: item?.requested_packages.map(
      (obj) => `${obj?.package_limits?.duration}, `
    ),
  }));
  return (
    <div>
      <div>
        <Table columns={cols} data={modifiedMystatementpackageDetails} footer={footer} />
      </div>
      <div className="th-color w-100 small-font p-3 d-flex justify-content-between">
        <div className="w-50">
          {/* <div className="row">
            <div className="col">Total Sale Pkg Hours</div>
            <div className="col">pkgsale</div>
          </div> */}
          <div className="row">
            <div className="col">Total Received Amount</div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">Balance Amount</div>
            <div className="col"></div>
          </div>
          <div className="row">
            <div className="col">Total P/L</div>
            <div className="col fa-fileinvo-doll-icon"></div>
          </div>
        </div>
        <div className="w-50 d-flex justify-content-end">
          {/* <div className="w-75 row">
            <div className="col-8">Total Downline Pkg+Hours</div>
            <div className="col-4">400000</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MystatementPacckage;
