export const Images = {
  TourLogo: "../assets/tours-logo.png",
  ProfileOne: "../assets/profile-pic1 (1).png",
  ProfileTwo: "../assets/profile-pic1 (2).png",
  ProfileThree: "../assets/profile-pic1 (3).png",
  ProfileFour: "../assets/profile-pic1 (4).png",
  PersonImg: "../assets/person-img.png",
  ProfileBanner: "../assets/profile-banner.png",
  StandardSmallImg: "../assets/standard-small.png",
  SilverSmallImg: "../assets/silver-small.png",
  GoldSmallImg: "../assets/gold-small.png",
  DiamondSmallImg: "../assets/diamond-small.png",
  VIPSmallImg: "../assets/vip-small.png",
  Diamond: "../assets/Diamond.png",
  Gold: "../assets/Gold.png",
  Silver: "../assets/Silver.png",
  Standard: "../assets/Standard.png",
  Vip: "../assets/VIP.png",
  QuestionMark: "../assets/question_mark.png",
  CheckedMark: "../assets/checked_Mark.png",
  SsevenLogo: "../assets/ssevenLogo.png",
  DarkBGImage: "../assets/dark_back_ground.png",
  dhawan_image: "../assets/dhawan.png",
  sachin_image: "../assets/sachin.png",
  kohli_image: "../assets/kohli.png",
  dhoni_image: "../assets/dhoni.png",
  rohit_image: "../assets/rohit.png",
  raina_image: "../assets/raina.png",
  GreenDoubleTick: "./assets/green_double_tick.png",
  LoginLogo: "./assets/Login_Logo.png",
  livematch:"../assets/match.webp",
  footballmatch:"../assets/football.webp",
  kabaddi:"../assets/kabaddi.jpg",
  tennis:"../assets/tennis.jpg"
};

export const ImageBaseUrl =
  "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com"; // singpore
