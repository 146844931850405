import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {
  ADD_NOTIFICATIONS_TEXT_MESSAGES,
  GET_ALL_PAYMENTS,
  GET_ALL_WEBSITES,
  GET_ALL_USERS,
} from "../../config/endpoints";

import { call } from "../../config/axios";
import { FaRegCalendarAlt } from "react-icons/fa";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";
import { useDispatch } from "react-redux";
import { setNotificationData } from "../../redux/actions/commonActions";
import DatePicker from "react-datepicker";

function TextMessage() {
  const dispatch = useDispatch();
  const [textmessage, setTextMessage] = useState({});
  const [error, setError] = useState("");
  const [textSubmitPopup, setTextSubmitPopup] = useState(false);
  const handelTextMessage = async (status) => {
    if (
      !(
        textmessage?.country_name ||
        textmessage?.notification_type ||
        textmessage?.description ||
        textmessage?.notification_place ||
        textmessage?.website_name
      )
    ) {
      return setError("missing required field");
    } else {
      setError("");
      await call(ADD_NOTIFICATIONS_TEXT_MESSAGES, {
        register_id: "company",
        country_name: textmessage.country_name,
        notification_type: textmessage.notification_type,
        website_name: textmessage.website_name,
        description: textmessage.description,
        start_date: textmessage.start_date,
        end_date: textmessage.end_date,
        publish_date: textmessage.publish_date,
        notification_place: textmessage.notification_place,
        status: status,
        active: status,
      }).then((res) => {
        setTextMessage(res?.data?.data);
        setTextSubmitPopup(true);

        setTimeout(() => {
          setTextSubmitPopup(false);
        });
        dispatch(setNotificationData("notification description"));
      });
    }
  };
  useEffect(() => {
    setTextMessage("");
  }, []);

  const handleChange = (e) => {
    setTextMessage({ ...textmessage, [e.target.name]: e.target.value });
  };

  const [websiteNames, setwebsiteNames] = useState([]);
  const getwebsiteNames = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_WEBSITES, payload)
      .then((res) => {
        setwebsiteNames(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getwebsiteNames();
  }, []);

  const [allUsers, setgetallUsers] = useState([]);
  const getallUsers = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_USERS, payload)
      .then((res) => {
        setgetallUsers(res?.data?.data?.Items);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getallUsers();
  }, []);

  const [allPayments, setAllPayments] = useState([]);
  const getPaymentWay = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_PAYMENTS, payload)
      .then((res) => {
        setAllPayments(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPaymentWay();
  }, []);

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const dateRef = useRef();
  const dateToRef=useRef();
  const dateFromRef=useRef();
  const toggleDate = () => {
    dateRef.current.setFocus();
  };
  const toggleDateTo = () => {
    dateToRef.current.setFocus();
  };
  const toggleDateFrom = () => {
    dateFromRef.current.setFocus();
  };

  return (
    <div className="p-4">
      <Container fluid className="my-2">
        <Row>
          <Col className="">
            <Container>
              <Row>
                <Col className="ps-0 pe-1">
                  <div>
                    <div className="clr-grey small-font my-2">
                      Select Website *
                    </div>
                    <select
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none small-font"
                      name="website_name"
                      id="website_name"
                      value={textmessage?.website_name || ""}
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="select" className="small-font">
                        select
                      </option>
                      <option value="All" className="small-font">
                        All
                      </option>
                      {websiteNames.map((obj, i) => (
                        <option
                          key={i}
                          value={obj.web_url}
                          className="small-font"
                        >
                          {obj.web_url}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                {/* </Row> */}
                {/* <Row> */}
                {/* <Col>
                  <div>
                    <div className="clr-grey small-font my-2">
                      Select User/Admin *
                    </div>
                    <select
                      name="user"
                      value={textmessage?.user || ""}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select</option>
                      <option value="All">All</option>
                      {allUsers &&

                        allUsers?.length > 0 &&
                        allUsers?.map((obj, i) => (
                          <option key={i} value={obj.user_name}>
                            {obj.user_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </Col> */}
                <Col className="ps-1 pe-0">
                  <div>
                    <div className="clr-grey small-font my-2">
                      Select Country *
                    </div>
                    <select
                      name="country_name"
                      value={textmessage?.country_name || ""}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="select" className="small-font">
                        select
                      </option>
                      <option value="All" className="small-font">
                        All
                      </option>
                      {allPayments?.map((obj, i) => (
                        <option
                          key={i}
                          value={obj.country_name}
                          className="small-font"
                        >
                          {obj.country_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="pe-1 ps-0">
                  <div>
                    <div className="clr-grey small-font my-2">
                      Notification Type *
                    </div>
                    <select
                      name="notification_type"
                      value={textmessage?.notification_type || ""}
                      onChange={(e) => handleChange(e)}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                    >
                      {/* {textmessage?.user === "All" ? (
                      <>
                        <option value="">Select</option>
                        <option value="mobile">Mobile</option>
                        <option value="web">Web</option>
                        <option value="web-pushnotification">
                          Web-PushNotification
                        </option>
                      </>
                    ) : (
                      <>
                        <option value="">Select</option>
                        <option value="personal">personal</option>
                      </>
                    )} */}
                      <>
                        <option value="" selected className="small-font">
                          Select
                        </option>
                        <option value="user" selected className="small-font">
                          chat promotion
                        </option>
                        <option value="Admin" selected className="small-font">
                          We2call promotion
                        </option>
                      </>
                    </select>
                  </div>
                </Col>
                <Col className="pe-0 ps-1">
                  <div>
                    <div className="clr-grey small-font my-2">
                      notification place *
                    </div>
                    <select
                      name="notification_place"
                      value={textmessage?.notification_place || ""}
                      className="w-100  small-font input-btn-bg px-2 py-3 all-none rounded all-none"
                      onChange={(e) => handleChange(e)}
                    >
                      {textmessage?.notification_type === "user" ? (
                        <>
                          <option value="" selected className="small-font">
                            Select
                          </option>
                          <option
                            value="annocement poster"
                            className="small-font"
                          >
                            annocement poster
                          </option>
                          <option
                            value=" website poster"
                            className="small-font"
                          >
                            website poster
                          </option>
                        </>
                      ) : (
                        <>
                          <option value="" selected className="small-font">
                            Select
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="">
            <div className="small-font my-2 clr-grey">Description</div>
            <textarea
              type="number"
              name="description"
              value={textmessage?.description || ""}
              onChange={(e) => handleChange(e)}
              placeholder="Type Here"
              className="w-100  small-font input-btn-bg rounded all-none py-3 px-2 h-85"
            ></textarea>
          </Col>
        </Row>
      </Container>
      <hr className="my-3" />
      <div className="w-25 mx-2">
        <div className="rounded-pill medium-font px-3 py-2 completed-btn w-50 text-center">
          Publish Details
        </div>
      </div>
      <Container fluid className="mt-2">
        <Row className="w-100">
          <Col className="">
            <div>
              <div className="medium-font mb-2 clr-grey">Active From</div>
              <div className="w-100 d-flex small-font input-btn-bg px-2 py-2 all-none rounded all-none align-items-center justify-content-between poniter"
              onClick={toggleDateFrom}>
                {/* <input
                  type="date"
                  className="login-input all-none w-100 pointer"
                  name="start_date"
                  value={textmessage?.start_date || ""}
                  onChange={(e) => handleChange(e)}
                ></input> */}
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  ref={dateFromRef}
                  className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none clr-white"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="From"
                />
                <FaRegCalendarAlt className="upload-icon clr-white p-1 font-size-30 cursor-pointer" onClick={toggleDateFrom}/>
              </div>
            </div>
          </Col>
          <Col className="">
            <div>
              <div className="medium-font mb-2 clr-grey">To</div>
              <div className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none d-flex flex-row align-items-center justify-content-between poniter"
              onClick={toggleDateTo}>
                {/* <input
                  name="end_date"
                  type="date"
                  className="login-input all-none w-100 pointer"
                  value={textmessage?.end_date || ""}
                  onChange={(e) => handleChange(e)}
                ></input> */}
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none clr-white"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="To"
                  ref={dateToRef}
                />
                <FaRegCalendarAlt className="upload-icon clr-white p-1 font-size-30" onClick={toggleDateTo}/>
              </div>
            </div>
          </Col>
          <Col className="">
            <div>
              <div className="medium-font mb-2 clr-grey">Publish Date</div>
              <div className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none d-flex flex-row align-items-center justify-content-between pointer"
              onClick={toggleDate}>
                {/* <input
                  name="publish_date"
                  type="date"
                  className="login-input all-none w-100 pointer"
                  value={textmessage?.publish_date || ""}
                  onChange={(e) => handleChange(e)}
                ></input> */}
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  ref={dateRef}
                  className="w-100  small-font input-btn-bg px-2 py-2 all-none rounded all-none clr-white "
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Publish-Date"
                />
                <FaRegCalendarAlt className="upload-icon clr-white p-1 font-size-30" onClick={toggleDate}/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="d-flex align-items-center flex-row px-3 mt-3">
        <input type="checkbox" />
        <div className="medium-font mx-2 clr-grey pointer">Publish Now</div>
      </div>
      {error && <div className="danger">{error}</div>}
      <div className="row w-100 d-flex flex-row justify-content-between my-3">
        <div className="col-sm d-flex flex-row">
          <button
            type="submit"
            className="add-button  medium-font rounded px-3 py-2 mx-2 all-none "
            onClick={() => handelTextMessage(true)}
          >
            Publish
          </button>
          <button
            type="submit"
            className="msg-deactive-button  medium-font rounded  mx-2 all-none px-3 py-2"
            onClick={() => handelTextMessage(false)}
          >
            Save As Draft
          </button>
        </div>
        <div class="col-sm d-flex justify-content-end">
          <button
            type="submit"
            className="msg-deactive-button  medium-font rounded  mx-2 all-none px-3 py-2"
          >
            Cancel
          </button>
        </div>
        <MatchSubmitPopup
          header={"BroadCast Added successfully"}
          state={textSubmitPopup}
          setState={setTextSubmitPopup}
        />
      </div>
    </div>
  );
}

export default TextMessage;
