import React, { useEffect, useRef, useState } from "react";
import Table from "../table/Table";
import Creatematch from "./Creatematch";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Images } from "../../images";
import { MdBlockFlipped } from "react-icons/md";
import BlockPopup from "../addDirector/BlockPopup";

const StreamingInterface = () => {
  const dropDownRef = useRef(null);

  const handleClickoutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setSeriesOption(false)
      setMatchOption(false)
    }
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setSeriesOption(false)
      setMatchOption(false)
    } 
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickoutside);
    return () => {
      document.removeEventListener("mousedown", handleClickoutside);
    };
  }, []);

  const [showOpenBlockPopup, setShowOpenBlockPopup] = useState(false);
  const handleBlock = () => {
    setShowOpenBlockPopup(!showOpenBlockPopup);
  };

  const [status, setStatus] = useState(["Active", "Inactive"]);
  const toggleStatus = (index) => {
    const updateStatus = [...status];
    updateStatus[index] =
      updateStatus[index] === "Active" ? "Inactive" : "Active";
    setStatus(updateStatus);
  };

  const sports = [
    "Cricket",
    "Football",
    "Tennis",
    "Kabaddi",
    "Horse Racing",
    "Greyhound Racing",
  ];
  const cols = [
    {
      header: (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="marginright-10 fw-600">LIVE DATE & TIME</div>
        </div>
      ),
      field: "date_time",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">NAME & ROLE</div>
        </div>
      ),
      field: "name_role",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">SERIES NAME / MATCH NAME</div>
        </div>
      ),
      field: "match_name",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">STREAMING</div>
        </div>
      ),
      field: "streaming",
    },
    {
      header: (
        <div className="d-flex justify-content-center align-items-center">
          <div className="marginright-10 fw-600">ACTION</div>
        </div>
      ),
      field: "action",
    },
  ];

  const cricketLiveMatchData = [
    {
      date_time: (
        <div className="d-flex flex-column th-color small-font">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column small-font">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: <div className="small-font">NewZealnad vs Aus</div>,
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.livematch} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center small-font">
          <div className="clr-green green-bg px-2 py-1 small-font">Active</div>
          <MdBlockFlipped
            className="th-color font-20 mt-3 pointer"
            onClick={handleBlock}
          />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color small-font">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column small-font">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: <div className="small-font">NewZealnad vs Aus</div>,
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.livematch} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center small-font">
          <div>
            <div
              className={`${
                status[0] === "Active" ? "clr-green green-bg" : "red-bg clr-red"
              } px-2 py-1`}
              onClick={() => toggleStatus(0)}
            >
              {status[0]}
            </div>
          </div>
          <div>
            <MdBlockFlipped
              className="th-color font-20 mt-3 pointer"
              onClick={handleBlock}
            />
          </div>
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color small-font">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column small-font">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: <div className="small-font">NewZealnad vs Aus</div>,
      streaming: (
        <div className="bs-percircl-icon small-font">
          <img src={Images.livematch} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center small-font">
          <div className="clr-green green-bg px-2 py-1 small-font">Active</div>
          <MdBlockFlipped
            className="th-color font-20 mt-3 pointer"
            onClick={handleBlock}
          />
        </div>
      ),
    },
  ];

  const footballLiveMatchData = [
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["NewZealnad vs Aus"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.footballmatch} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["NewZealnad vs Aus"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.footballmatch} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["NewZealnad vs Aus"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.footballmatch} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
  ];
  const tennisLiveMatchData = [
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["NewZealnad vs Aus"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.tennis} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["NewZealnad vs Aus"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.tennis} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["NewZealnad vs Aus"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.tennis} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
  ];
  const kabaddiLiveMatchData = [
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["telugu titans vs patna pirates"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.kabaddi} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["tamil talaivas vs pune paltans"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.kabaddi} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
    {
      date_time: (
        <div className="d-flex flex-column th-color">
          <span>01/08/25</span>
          <span>10:00AM</span>
        </div>
      ),
      name_role: (
        <div className="d-flex flex-column">
          <span className="th-color">Srinivas</span>
          <spna className="clr-yellow">SuperAdmin</spna>
        </div>
      ),
      match_name: ["jaipur pink panthes vs haryana steelers"],
      streaming: (
        <div className="bs-percircl-icon">
          <img src={Images.kabaddi} alt="" className="h-20vh" />
        </div>
      ),
      action: (
        <div className="d-flex flex-column align-items-center">
          <div className="c,r-green green-bg px-2 py-1 ">Active</div>
          <MdBlockFlipped className="th-color font-20 mt-3" />
        </div>
      ),
    },
  ];
  const [seriesOption, setSeriesOption] = useState(false);
  const seriesDropDown = () => {
    setSeriesOption(!seriesOption);
  };
  const [series, setSeries] = useState();
  const seriesList = () => {
    setSeries(!series);
  };
  const [matchOption, setMatchOption] = useState(false);
  const matchDropDown = () => {
    setMatchOption(!matchOption);
  };

  const [match, setMatch] = useState(false);
  const matchList = () => {
    setMatch(!match);
  };

  const [activeData, setActiveData] = useState(cricketLiveMatchData);
  const [activeTab, setActiveTab] = useState("Cricket");
  const handleButtonClick = (btn) => {
    setActiveTab(btn);
    switch (btn) {
      case "Cricket":
        setActiveData(cricketLiveMatchData);
        break;
      case "Football":
        setActiveData(footballLiveMatchData);
        break;
      case "Tennis":
        setActiveData(tennisLiveMatchData);
        break;
      case "Kabaddi":
        setActiveData(kabaddiLiveMatchData);
        break;
      case "Horse Racing":
        setActiveData(kabaddiLiveMatchData);
        break;
      case "Greyhound Racing":
        setActiveData(kabaddiLiveMatchData);
        break;
      default:
        setActiveData([]);
    }
  };

  const [selectedSeries, setSelectedSeries] = useState("Select");

  const seriesOptions = [
    "IndiaSeries",
    "DubaiSeries",
    "NewZealandSeries",
    "IndiaSeries",
    "DubaiSeries",
    "NewZealandSeries",
  ];

  const toggleSeriesDropdown = () => {
    setSeriesOption((prev) => !prev);
    setMatchOption(false);
  };

  const handleSeriesSelect = (series) => {
    setSelectedSeries(series);
    setSeriesOption(false);
  };

  const [selectedMatch, setSelectedMatch] = useState("Select");

  const matchOptions = [
    "India vs Aus",
    "USA vs Africa",
    "NewZealand vs India",
    "IndiaSeries",
    "DubaiSeries",
    "NewZealandSeries",
  ];

  const toggleMatchDropdown = () => {
    setMatchOption((prev) => !prev);
    setSeriesOption(false);
  };

  const handleMatchSelect = (match) => {
    setSelectedMatch(match);
    setMatchOption(false);
  };

  return (
    <div className="p-1">
      <div className="d-flex p-3">
        {sports.map((btn, index) => (
          <div
            key={index}
            className={`tab-button medium-font px-3 py-2 white-space ${
              activeTab === btn ? "active mx-2" : "mx-2"
            }`}
            onClick={() => handleButtonClick(btn)}
          >
            {btn}
          </div>
        ))}
      </div>
      <div className="hr-line"></div>
      <div className="d-flex align-items-center th-color p-3">
        <div className="col-3 mx-2">
          <div className="th-color mb-1 small-font">Series</div>
          <div className="position-relative" ref={dropDownRef}>
            <div
              className="py-2 blue-shade-bg w-80 d-flex flex-between th-color small-font pointer"
              onClick={toggleSeriesDropdown}
            >
              <span className="mx-2 pointer">{selectedSeries}</span>
              {seriesOption ? (
                <FaChevronUp className="mx-2" />
              ) : (
                <FaChevronDown className="mx-2" />
              )}
            </div>
            <div className="mt-1 ">
              {seriesOption && (
                <div className="blue-shade-bg dropdown-scroll position-absolute w-80 h-20vh small-font">
                  {seriesOptions.map((series, index) => (
                    <div
                      key={index}
                      className="py-2 border-bottom-white px-1 clr-hover"
                      onClick={() => handleSeriesSelect(series)}
                    >
                      {series}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="blue-shade-bg"></div>
        <div className="col-3 ml-minus-32px">
          <div className="small-font th-color mb-1">Match</div>
          <div className="position-relative ">
            <div
              className="py-2 blue-shade-bg w-80 d-flex flex-between th-color small-font pointer"
              onClick={toggleMatchDropdown}
            >
              <span className="mx-2 ">{selectedMatch}</span>
              {matchOption ? (
                <FaChevronUp className="mx-2" />
              ) : (
                <FaChevronDown className="mx-2" />
              )}
            </div>
            <div className="mt-1">
              {matchOption && (
                <div className="blue-shade-bg dropdown-scroll position-absolute w-80 small-font">
                  {matchOptions.map((match, index) => (
                    <div
                      key={index}
                      className="py-2 border-bottom-white px-1 clr-hover"
                      onClick={() => handleMatchSelect(match)}
                    >
                      {match}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-2 ml-minus-35px">
          <div className="blue-bg clr-white px-3 w-80 py-2 mx-3 mt-3 text-center small-font cursor-pointer">
            Submit
          </div>
        </div>
        <div className="col-1"></div>

        <div className="col-3 col-lg-3">
          <div className="blue-bg white-space clr-white px-2 w-100 py-2 mx-3 mt-3 text-center small-font pointer white-space">
            Block All live Streamings
          </div>
        </div>
      </div>
      <div className="hr-line"></div>

      <div className="small-font">
        <Table columns={cols} data={activeData} />
      </div>
      <BlockPopup
        show={showOpenBlockPopup}
        setShow={setShowOpenBlockPopup}
        title="live stream match"
      />
    </div>
  );
};

export default StreamingInterface;
