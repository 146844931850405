import React, { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "./styles.css";
import MatchSubmitPopup from "./../../matchpopups/MatchSubmitPopup";

function AddTeamPopUp({ show, setShow, heading }) {
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [role, setRole] = useState("");

  const [lastName, setLastName] = useState("");

  const [ulCommision, setUlCommision] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [err, setErr] = useState("");
  const [confrmPasswordType, setConfrmPasswordType] = useState("password");
  const [confrmPasswordInput, setConfrmPasswordInput] = useState("");

  const handlePasswordChange = (evnt) => {
    setPasswordInput({
      ...passwordInput,
      [evnt.target.name]: evnt.target.value,
    });
  };
  const handleConfirmPasswordChange = (evnt) => {
    setConfrmPasswordInput({
      ...confrmPasswordInput,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPassword = () => {
    if (confrmPasswordType === "password") {
      setConfrmPasswordType("text");
      return;
    }
    setConfrmPasswordType("password");
  };

  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const togglePopup = () => {
    setShowSubmitPopup(!showSubmitPopup);
  };

  return (
    <div className="modal fade bd-example-modal-lg container mt-5">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="match-share-modal payment-modal"
      >
        <Modal.Header closeButton>
          <div className="w-100 mt-4">
            <h5 className="text-center mb-3">{heading}</h5>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 px-4">
            <Row>
              <Col>
                <div className="small-font my-1">
                  Select Area <span className="red-clr">*</span>
                </div>
                <select
                  value={role || " "}
                  name="account_role"
                  onChange={(e) => setRole(e.target.value)}
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                >
                  <option value="" className="small-font">
                    Select
                  </option>
                  <option value="director" className="small-font">
                    Hyderabad{" "}
                  </option>
                  <option value="superadmin" className="small-font">
                    Dubai
                  </option>
                </select>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex flex-column w-100 mt-2">
                  <div className="small-font mb-1 mt-1">
                    User ID <span className="red-clr">*</span>
                  </div>
                  <div className="w-100">
                    <input
                      type="text"
                      placeholder="Enter User Id"
                      name="user_name"
                      className="w-100 small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    ></input>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="small-font my-1">
                  Password <span className="red-clr">*</span>
                </div>
                <div className="d-flex justify-content-between flex-row aligin-items-center input-btn-bg w-100 rounded">
                  <input
                    type={passwordType}
                    onChange={handlePasswordChange}
                    value={passwordInput.password}
                    name="password"
                    placeholder="Enter"
                    className="w-100 login-inputs small-font input-btn-bg px-2 py-1 all-none rounded all-none"
                  ></input>
                  <button
                    onClick={togglePassword}
                    className="all-none input-btn-bg"
                  >
                    {passwordType === "password" ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
              </Col>
              <Col>
                <div className="small-font my-1">
                  Confirm Password <span className="red-clr">*</span>
                </div>
                <div className="d-flex justify-content-between flex-row aligin-items-center input-btn-bg w-100 rounded">
                  <input
                    type={confrmPasswordType}
                    onChange={handleConfirmPasswordChange}
                    value={passwordInput.confirm_password}
                    name="confirm_password"
                    placeholder="Re-enter"
                    className="w-100  small-font login-inputs input-btn-bg px-2 py-1 all-none rounded all-none"
                  ></input>
                  <button
                    onClick={toggleConfirmPassword}
                    className="all-none input-btn-bg"
                  >
                    {confrmPasswordType === "password" ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="small-font my-1">
                  First Name <span className="red-clr">*</span>
                </div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="first_name"
                  className="w-100  small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                  value={firstName}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(value)) {
                      setFirstName(value);
                    }
                  }}
                ></input>
              </Col>
              <Col>
                <div className="small-font my-1">Last Name </div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="last_name"
                  className="w-100  small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                  value={lastName}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[A-Za-z\s]*$/.test(value)) {
                      setLastName(value);
                    }
                  }}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="small-font my-1">
                  Phone<span className="red-clr">*</span>
                </div>
                <input
                  type="text"
                  placeholder="Enter"
                  name="ul_commision"
                  className="w-100 small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                  value={ulCommision}
                  maxLength={10}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setUlCommision(value);
                    }
                  }}
                ></input>
              </Col>

              <Col>
                <div className="small-font my-1">
                  Role <span className="red-clr">*</span>
                </div>
                <select
                  name="account_role"
                  className="w-100  small-font login-inputs input-btn-bg px-2 py-2 all-none rounded all-none"
                >
                  <option value="" className="small-font">
                    Select
                  </option>
                  <option value="director" className="small-font">
                    Director{" "}
                  </option>
                  <option value="superadmin" className="small-font">
                    SuperAdmin
                  </option>
                </select>
              </Col>
            </Row>

            {err && <div className="error-message mb-1">{err}</div>}
            <div className="d-flex justify-content-center w-100 my-4">
              <div
                className="add-button rounded p-2 w-50 medium-font text-center pointer"
                onClick={togglePopup}
              >
                Create
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <MatchSubmitPopup
        header={"Add Team created Successfully"}
        state={showSubmitPopup}
        setState={setShowSubmitPopup}
      />
    </div>
  );
}

export default AddTeamPopUp;
