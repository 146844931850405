import React from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Images } from "../../images";

const ViewPosterPopup = ({ showModal, setShowModal, title }) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      className="match-declaration-modal"
    >
      <div className="sidebar-bg th-color p-2 d-flex flex-column radius">
        <span className="flex-end font-24 cursor-pointer">
          <IoClose onClick={() => setShowModal(false)} />
        </span>
        {title === "scroll notification" ? (
          <div className="d-flex flex-column">
            <div className="flex-center fw-600 large-font clr-white">Notification Text</div>
            <div className="p-3">
            <div className="blue-shade-bg br-20 p-2">
              <span>
                the activity or business of sending out programmes on television
                or radio: Huge amounts of money are spent on sports
                broadcasting. He is hoping to find a job in radio broadcasting.
              </span>
            </div>
            </div>
          </div>
        ) : (
         
           <div className="d-flex flex-column">
           <div className="flex-center medium-font fw-600 clr-white">Poster</div>
           <div className="p-3">
           <div className="blue-shade-bg br-20 p-2">
           <div className="flex-center">
            <img src={Images.raina_image} alt="" />
          </div>
           </div>
           </div>
         </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewPosterPopup;
