import React from 'react'
import { Modal } from 'react-bootstrap'
import { Images } from '../../images'
import { IoClose } from 'react-icons/io5'

const MatchFullScreen = ({showModal,setShowModal}) => {
  return (
    <div>
        <Modal show={showModal} onHide={()=>setShowModal(false)} centered size="lg">
            <div className='border br-10'>
                <span className='flex-end mx-1 my-2' onClick={()=>setShowModal(false)}>
                <IoClose className='font-20'/>
                </span>
                <img src={Images.livematch} alt=""/>
            </div>
        </Modal>
    </div>
  )
}

export default MatchFullScreen