import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Images } from "../../images";
import { MdEdit, MdOutlineEdit } from "react-icons/md";
import MatchSubmitPopup from "../../matchpopups/MatchSubmitPopup";

const EditPopup = ({ show, setShow }) => {
  const [profileImage, setProfileImage] = useState(Images.ProfileFour);
  const fileInputRef = useRef(null);

  const handleEditClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
    }
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleSubmit = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="match-declaration-modal"
      >
        <div className="sidebar-bg br-10 d-flex flex-column th-color p-3">
          <span className="flex-end" onClick={() => setShow(false)}>
            <IoClose className="font-24 cursor-pointer" />
          </span>
          <div className="flex-center">
            <div className=" text-center medium-font fw-500">Profile</div>
          </div>
          <div className="my-2 flex-center position-relative">
            <img src={profileImage} alt="" className="h-10vh" />
            <div
              className="position-absolute-edit-icon"
              onClick={handleEditClick}
            >
              <MdOutlineEdit className="edit-icon p-1 font-24 clr-white" />
              <input
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          </div>
          <div className="d-flex w-100 flex-column">
            <div className="col-12 d-flex flex-column ">
              <div className="medium-font">
                Name <span className="clr-red">*</span>
              </div>
              <div className="blue-shade-bg small-font px-2 py-2">
                <input
                  type="text"
                  className="all-none2 clr-white"
                  placeholder="Management"
                />
              </div>
            </div>
            <div className="col-12 d-flex flex-column medium-font mt-1">
              <div className="medium-font">
                Role <span className="clr-red">*</span>
              </div>
              <div className="blue-shade-bg small-font px-2 py-2">
                <input
                  type="text"
                  className="all-none2 clr-white"
                  placeholder="Management"
                />
              </div>
            </div>
          </div>

          <div className="flex-center pt-3 pb-1">
            <div
              className="blue-bg py-2 w-50 cursor-pointer text-center me-1 medium-font"
              onClick={handleSubmit}
            >
              Save
            </div>
            <div
              className="blue-shade-bg py-2 w-50 cursor-pointer text-center ms-2 medium-font input-bgg"
              onClick={() => setShow(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>
      <MatchSubmitPopup
        header={"Your profile Edited Successfully"}
        state={showPopup}
        setState={setShowPopup}
      />
    </div>
  );
};

export default EditPopup;
