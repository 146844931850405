import React from "react";
import Totalaccount from "../home/Totalaccount";
import PackageLiveLine from "./PackageLiveLine";
import AccountData from "../home/AccountData";

const LiveLineStreamingPackage = () => {
  return (
    <div>
      <div className="px-2">
        <AccountData />
      </div>
      <div className="p-2">
        <PackageLiveLine/>
      </div>
    </div>
  );
};

export default LiveLineStreamingPackage;
