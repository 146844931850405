import React, { useEffect, useRef, useState } from "react";
import Table from "../table/Table";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import PackagePopup from "../Popups/PackagePopup";

const PackageLiveLine = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [selectedDuration, setSelectedDuration] = useState();
  const [selectedNumber, setSelectedNumber] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const options = [
    "All Package",
    "Calling Package",
    "Live Streaming",
    "Line Package",
  ];
  const [selectedOption, setSelectedOption] = useState("All Package");
  const [selectedOptionBtnColor, setSelectedOptionBtnColor] = useState("30");
  const [selectedOptionBtnColorCalling, setSelectedOptionBtnColorCalling] = useState("Hourly");
  const [showAddWebPopup, setShowAddWebPopup] = useState(false);
  const [openPackageData, setOpenPackageData] = useState({});
  const dropdownRef = useRef(null);

  const dummyWebsites = [
    {
      date: (
        <div className="d-flex flex-column">
          <span>{"20-03-24"}</span>
          <span>{"12:00"}</span>
        </div>
      ),
      pkg_id: 1234567890,
      inactive_date: (
        <div className="d-flex flex-column">
          <span>{"20-03-24"}</span>
          <span>{"12:00"}</span>
        </div>
      ),
      pkg_name: (
        <div className="d-flex flex-column">
          <span>{"20-03-24"}</span>
          <span>{"12:00"}</span>
        </div>
      ),
      pkg_cost: (
        <div className="d-flex flex-column">
          <span>{"20-03-24"}</span>
          <span>{"12:00"}</span>
        </div>
      ),
      pkg_hours: (
        <div className="d-flex flex-column">
          <span>{"20-03-24"}</span>
          <span>{"12:00"}</span>
        </div>
      ),
      ltd_mem: 10,
      discount: "5%",
      card_details: "No of members allows to meeting ",
      status: <div className="clr-green light-blue p-1 br-10">Active</div>,
    },
  ];
  const dropdownData = {
    number: [
      { id: 1, label: "1", value: "1" },
      { id: 2, label: "2", value: "2" },
      { id: 3, label: "3", value: "3" },
      { id: 4, label: "4", value: "4" },
      { id: 5, label: "5", value: "5" },
    ],
    duration: [
      { id: 1, label: "Hourly", value: "hourly" },
      { id: 2, label: "Monthly", value: "monthly" },
      { id: 3, label: "Yearly", value: "yearly" },
    ],
    createPackage: [
      { id: 1, label: "Standard", value: "standard" },
      { id: 2, label: "Silver", value: "silver" },
      { id: 3, label: "Gold", value: "gold" },
      { id: 4, label: "Diamond", value: "diamond" },
      { id: 5, label: "VIP", value: "vip" },
    ],
    createPackageLiveLine: [
      { id: 1, label: "Standard", value: "standard" },
      { id: 2, label: "Silver", value: "silver" },
      { id: 3, label: "Gold", value: "gold" },
      { id: 4, label: "Diamond", value: "diamond" },
      { id: 5, label: "VIP", value: "vip" },
      { id: 6, label: "Live Streaming", value: "live_streaming" },
      { id: 7, label: "Line Streaming", value: "line_streaming" },
    ],
    headings: [
      { id: 5, label: "Add Logo", value: "Add Logo" },
      { id: 5, label: "Add Offer text", value: "Add Offer text" },
      { id: 5, label: "Create Package", value: "Create Package" },
    ],
  };

  const handleOptionClickBtnColor = (option) => {
    setSelectedOptionBtnColor(option);
  };
  const handleOptionClickBtnColorCalling = (option) => {
    setSelectedOptionBtnColorCalling(option);
  };

  const toggleDropdownList = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const toggleDropdown = (heading) => {
    setActiveDropdown((prev) => (prev === heading ? null : heading));
    setSelectedOffer("");
    setSelectedDuration("");
    setSelectedNumber("");
  };

  const handleOfferClick = (offer, position) => {
    setSelectedOffer({ offer, position });
    setSelectedDuration("");
    setSelectedNumber("");
  };

  const handleDurationClick = (duration, position) => {
    setSelectedDuration({ duration, position });
    setSelectedNumber("");
    setOpenPackageData(position);
    if (position !== "Add Offer text") {
      setShowAddWebPopup(true);
    } else {
      setShowAddWebPopup(false);
    }
  };

  const handleSelectNumber = (id, label) => {
    setShowAddWebPopup(true);
  };

  const cols = [
    { header: <div className="fw-600">DATE</div>, field: "date" },
    { header: <div className="fw-600">PKG ID</div>, field: "pkg_id" },
    {
      header: <div className="fw-600">INACTIVE DATE</div>,
      field: "inactive_date",
    },
    { header: <div className="fw-600">PKG NAME</div>, field: "pkg_name" },
    { header: <div className="fw-600">PKG COST</div>, field: "pkg_cost" },
    { header: <div className="fw-600">PKG HOURS</div>, field: "pkg_hours" },
    { header: <div className="fw-600">LTD MEM</div>, field: "ltd_mem" },
    { header: <div className="fw-600">DISCOUNT</div>, field: "discount" },
    {
      header: <div className="fw-600">CARD DETAILS</div>,
      field: "card_details",
    },
    { header: <div className="fw-600">STATUS</div>, field: "status" },
  ];
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
      setSelectedOffer(null);
      setSelectedDuration(null);
    }
  };

  return (
    <div className="w-100 clr-white medium-font">
      <div className="w-100 d-flex flex-between">
        <div className="medium-font px-2">All Package</div>
        <div
          className="w-50 py-2 d-flex flex-between position-relative medium-font fw-500"
          ref={dropdownRef}
        >
          {["Add Logo", "Add Offer text", "Create Package"].map(
            (heading, index) => (
              <div className="w-30 pointer position-relative" key={index}>
                <div
                  className="package-btn w-100 position-relative my-2 d-flex flex-between"
                  onClick={() => toggleDropdown(heading)}
                >
                  {heading}
                  {activeDropdown === heading ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  )}
                </div>
                {activeDropdown === heading && (
                  <div
                    className="day-button pkg-dropdown br-10"
                    ref={dropdownRef}
                  >
                    {(heading === "Create Package"
                      ? dropdownData.createPackageLiveLine
                      : dropdownData.createPackage
                    ).map((item) => (
                      <div
                        key={item.id}
                        className="border-bottom-white py-2 d-flex hover-container"
                        onClick={() => handleOfferClick(item.label, heading)}
                      >
                        <span className="blue-line"></span>
                        <span className="px-2 pointer">{item.label}</span>
                      </div>
                    ))}
                  </div>
                )}

                {selectedOffer?.position === heading && (
                  <div
                    className="day-button pkg-dropdown-duration br-10 position-absolute "
                    ref={dropdownRef}
                  >
                    {dropdownData.duration.map((item) => (
                      <div
                        key={item.id}
                        className="border-bottom-white py-2 d-flex hover-container"
                        onClick={() => handleDurationClick(item.label, heading)}
                      >
                        <span className="blue-line"></span>
                        <span className="px-2 pointer">{item.label}</span>
                      </div>
                    ))}
                  </div>
                )}
                {selectedDuration?.position === heading &&
                  heading !== "Create Package" &&
                  heading !== "Add Logo" && (
                    <div
                      className={`pkg-dropdown-number day-button br-10 position-absolute`}
                      ref={dropdownRef}
                    >
                      {dropdownData.number.map((item) => (
                        <div
                          key={item.id}
                          className="border-bottom-white py-2 d-flex hover-container"
                          onClick={() =>
                            handleSelectNumber(item.label, heading)
                          }
                        >
                          <span className="blue-line"></span>
                          <span className="px-2 pointer">{item.label}</span>
                        </div>
                      ))}
                    </div>
                  )}
              </div>
            )
          )}
        </div>
      </div>
      <div className="w-100 package-container">
        <div className="package-bg p-2 br-10 w-20 border-white">
          <div
            className="d-flex flex-between pointer"
            onClick={toggleDropdownList}
          >
            <span>{selectedOption}</span>
            <MdKeyboardArrowDown />
          </div>
          {isOpen && (
            <div className="dropdown-list blue-theme br-10">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="border-white pointer p-2"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="my-2 py-1 fa-fileinvo-doll-icon pkg-bg w-10 text-center br-10">
          <span>{selectedOption}</span>
        </div>
        {selectedOption === "Live Streaming" && (
          <div className="d-flex w-50 medium-font pointer">
            <div
              className={`w-50 p-2 br-10 text-center ${
                selectedOptionBtnColor === "30"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColor("30")}
            >
              30 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${
                selectedOptionBtnColor === "90"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColor("90")}
            >
              90 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${
                selectedOptionBtnColor === "180"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColor("180")}
            >
              180 Days
            </div>
          </div>
        )}
          {selectedOption === "Line Package" && (
          <div className="d-flex w-50 medium-font pointer">
            <div
              className={`w-50 p-2 br-10 text-center ${
                selectedOptionBtnColor === "30"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColor("30")}
            >
              30 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${
                selectedOptionBtnColor === "90"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColor("90")}
            >
              90 Days
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${
                selectedOptionBtnColor === "180"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColor("180")}
            >
              180 Days
            </div>
          </div>
        )}
        {selectedOption === "Calling Package" && (
          <div className="d-flex w-50 medium-font pointer">
            <div
              className={`w-50 p-2 br-10 text-center ${
                selectedOptionBtnColorCalling === "Hourly"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColorCalling("Hourly")}
            >
              Hourly
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${
                selectedOptionBtnColorCalling === "Monthly"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColorCalling("Monthly")}
            >
              Monthly
            </div>
            <div
              className={`w-50 p-2 br-10 mx-1 text-center ${
                selectedOptionBtnColorCalling === "Yearly"
                  ? "selected-option"
                  : "light-blue"
              }`}
              onClick={() => handleOptionClickBtnColorCalling("Yearly")}
            >
             Yearly
            </div>
          </div>
        )}
      </div>
      <Table columns={cols} data={dummyWebsites} />
      {showAddWebPopup && (
        <PackagePopup
          showAddWebPopup={showAddWebPopup}
          setShowAddWebPopup={setShowAddWebPopup}
          openPackageData={openPackageData}
        />
      )}

    </div>
  );
};

export default PackageLiveLine;
