import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { BiSolidCamera } from "react-icons/bi";
import { ImAttachment } from "react-icons/im";
import { MdMicNone } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import moment from "moment";
import { RiCheckDoubleLine } from "react-icons/ri";
import { Images } from "../../images";
import { GET_ALL_USERS } from "../../config/endpoints";
import { GET_USER_MESSAGES, GENERATE_SIGNED_URL } from "../../config/endpoints";
import { call } from "../../config/axios";
import { open, send } from "../utils/WebSocket";
import { RiSendPlaneFill } from "react-icons/ri";
import { PiVideoCameraBold } from "react-icons/pi";
import { IoCallOutline } from "react-icons/io5";

function Chats() {
  const register_id = "company";
  const account_role = "company";
  const ImageBaseUrl =
    "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";

  const [clientsData, setClientsData] = useState([]);
  const [supportData, setSupportData] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserImage, setSelectedUserImage] = useState();
  const [userName, setUserName] = useState("");
  const [singedUrl, setSignedUrl] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  const handleUserClick = async (Id, name, profileImage) => {
    setSelectedUser(Id);
    await getAllUserMessages(Id);
    setUserName(name);
    setSelectedUserImage(profileImage);
  };

  const getAllUserData = async () => {
    await call(GET_ALL_USERS, {
      register_id,
      account_role,
    })
      .then((res) => {
        setClientsData(res?.data?.data.Items);
        handleUserClick(
          res?.data?.data[0]?.register_id,
          res?.data?.data[0]?.user_name,
          res?.data?.data[0]?.profile_image
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  const inputHandler = async () => {
    // setUserInput("");
    // await send(userInput, selectedUser);
    if (profileImage) {
      await imageUpploadToBucket();
      const imageUrl = `${ImageBaseUrl}/${"chat-images"}/${register_id}/${
        profileImage.name
      }`;
      await send(imageUrl, "text", selectedUser);
      setUserInput("");
      setProfileImage(null);
    } else if (userInput.trim() !== "") {
      await send(userInput, "text", selectedUser);
      setUserInput("");
    }
  };

  const addMessage = (message, msg_c = 0) => {
    let temp = { message, ts: new Date().getTime(), msg_c };
    setSupportData((prev) => {
      if (!Array.isArray(prev)) {
        return [temp];
      }
      return [...prev, temp];
    });
  };

  const [file, setFile] = useState([]);
  const handleChange = (e) => {
    setFile([file, e.target.files[0]]);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };
  const hanldeKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftyKey) {
      event.preventDefault();
      inputHandler();
    }
  };

  const getAllUserMessages = async (Id) => {
    await call(GET_USER_MESSAGES, {
      from_user_id: Id,
      to_user_id: register_id,
    })
      .then(async (res) => {
        setSupportData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateSignedUrl = async (filename) => {
    await call(GENERATE_SIGNED_URL, {
      register_id,
      event_type: "user_profile_image",
      folder_name: "chat-images",
      file_name: filename,
    })
      .then(async (res) => {
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
      })
      .catch((err) => {
        console.log("generating signed url error", err);
      });
  };

  const imageUpploadToBucket = async () => {
    singedUrl &&
      profileImage &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: profileImage,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          console.log("response", res);
          return true;
        })
        .catch((err) => {
          console.log("err: ", err);
        }));
  };

  const onMessageRecieve = (event) => {
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    if (
      selectedUser === msg?.to_user_id ||
      selectedUser === msg?.from_user_id
    ) {
      addMessage(msg);
    }
  };
  useEffect(() => {
    getAllUserMessages();
  }, []);

  useEffect(() => {
    open({ onmessage: onMessageRecieve });
  }, [supportData]);

  const uploadfileInputRef = useRef(null);
  const handleUploadFileSelect = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const filename = file.name;
      setProfileImage(file);
      generateSignedUrl(filename);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };
  const handleUploadButtonClick = () => {
    uploadfileInputRef.current.click();
  };

  const chatRef = useRef(null);
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [supportData]);

  return (
    <div class="w-100 p-3 inbox_msg">
      <div class="w-25 inbox_people p-3">
        <h5 className="th-color">Support</h5>
        <input className="srch_bar-input rounded p-2" placeholder="Search..." />
        <div class="d-flex align-items-center py-2">
          <LuUsers className="upload-icon" />
          <span className="clr-cornflower large-font mx-2">Contacts</span>
        </div>
        <hr className="chat-hr" />
        <div className="inbox-chat-contacts">
          <div class="chat_people d-flex justify-content-between  flex-column">
            {clientsData &&
              clientsData?.length > 0 &&
              clientsData?.map((user, index) => (
                <div
                  key={index}
                  onClick={() =>
                    handleUserClick(
                      user?.register_id,
                      user?.user_name,
                      user?.profile_image
                    )
                  }
                >
                  <div class="cursor-pointer chat_people py-1 d-flex justify-content-between align-items-center">
                    <div class="chat_img">
                      <img
                        className="rounded-circle"
                        src={user?.profile_image}
                        alt="sunil"
                      />
                    </div>
                    <div class="chat_ib">
                      <h5>{user?.user_name}</h5>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div class="w-75 mesgs">
        <div className="border-left d-flex flex-row justify-content-between align-items-center py-2 px-3 chat-container-box">
          <div class="chat_people d-flex align-items-center">
            <div class="chat_img">
              {userName === "" ? null : (
                <img
                  className="rounded-circle"
                  src={selectedUserImage}
                  alt="sunil"
                />
              )}
            </div>
            <div className="chat-head ps-2">{userName}</div>
          </div>
          {/* <div>
            <PiVideoCameraBold className="chat-icon mx-2 cursor-pointer" />
            <IoCallOutline className="chat-icon mx-2 cursor-pointer" />
          </div> */}
        </div>
        <div class="msg_history p-3">
          {supportData &&
            supportData?.length > 0 &&
            supportData?.map((item, index) => {
              let senderId = item?.from_user_id === register_id ? true : false;
              return (
                <div key={index}>
                  <div class="outgoing_msg">
                    <div key={index} class="sent_msg">
                      <p>
                        
                        {typeof item.message === "string" &&
                        item.message.includes("http") ? (
                          <img src={item?.message} alt="chat-image" />
                        ) : typeof item.message != "object" &&
                          !Array.isArray(item.message) ? (
                          <div>{item.message}</div>
                        ) : (
                          <></>
                        )}
                      </p>
                      <div className="d-flex align-items-center justify-content-end">
                        <span class="time_date">
                          {moment(item.ts).format("hh:mm:ss A")}
                        </span>
                        {senderId && (
                          <RiCheckDoubleLine
                            style={{ fontSize: "20px", color: "#70dc37" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="border-left d-flex flex-row justify-content-around align-items-center p-2 chat-container-box">
          <div class="type_msg w-90 mx-2 rounded">
            <div class="input_msg_write">
              <input
                type="text"
                class="write_msg px-2"
                className="ps-3"
                name="postMessage"
                rows={1}
                value={userInput}
                autoFocus
                placeholder="Type a message"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                onKeyDown={(e) => userInput && hanldeKeyDown(e)}
              />
              <RiSendPlaneFill
                className="msg_send_btn"
                type="button"
                onClick={() => inputHandler()}
              />
            </div>
          </div>
          <div className="d-flex w-10 flex-row justify-content-around align-items-center">
            {/* <div className="bg-clr-chat p-2 rounded">
              <label htmlFor="camera-button  cursor-pointer">
                <BiSolidCamera className="chat-icon cursor-pointer" />
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                // onChange={(e) => handleChange(e)}
              />
            </div> */}
            <div className="bg-clr-chat p-2 rounded cursor-pointer">
              <label htmlFor="attachment">
                <ImAttachment className="chat-icon cursor-pointer" />
              </label>
              <input
                type="file"
                ref={uploadfileInputRef}
                id="attachment"
                style={{ display: "none" }}
                onChange={(e) => handleUploadFileSelect(e)}
              />
            </div>
            {/* <div className="bg-clr-chat p-2 rounded cursor-pointer">
              <MdMicNone className="chat-icon cursor-pointer" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chats;
